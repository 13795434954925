import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { CLOUD_FUNCTION_URL, postRequest } from './Axios';
import { Mixpanel } from '../analytics/Mixpanel';
import { getCurrentDate, getDeviceId } from '../utils/utils';
import { getStorage } from 'firebase/storage';
import {
	fetchAndActivate,
	getRemoteConfig,
	getValue,
} from 'firebase/remote-config';

// TODO: Add SDKs for Firebase products that you want to use

// add this config in env
const firebaseConfig = {
	apiKey: 'AIzaSyDSg1X4BeBxcbApXKgMaftCzDACXKnS9UE',
	authDomain: 'seek-c3955.firebaseapp.com',
	projectId: 'seek-c3955',
	storageBucket: 'seek-c3955.appspot.com',
	messagingSenderId: '1084470586799',
	appId: '1:1084470586799:web:204180414fe4ad61a6935a',
	measurementId: 'G-0MBVXXGMZP',
};
const app = initializeApp(firebaseConfig);
const remoteConfig = getRemoteConfig(app);

remoteConfig.settings = {
	minimumFetchIntervalMillis: 3600000, // Fetch new values every 1 hour
};

export const auth = getAuth(app);
export const storage = getStorage(app);

export const fetchABExperimentGroupKey = async () => {
	try {
		await fetchAndActivate(remoteConfig);
		const plan_experiment_key = getValue(
			remoteConfig,
			'plan_experiment_key'
		).asString();
		const onboarding_experiment_key = getValue(
			remoteConfig,
			'onboarding_experiment_key'
		).asString();
		const pricing_experiment_key = getValue(
			remoteConfig,
			'pricing_experiment_key'
		).asString();
		const subscription_experiment_key = getValue(
			remoteConfig,
			'subscription_experiment_key'
		).asString();

		console.log('plan_experiment_key', plan_experiment_key);
		console.log('onboarding_experiment_key', onboarding_experiment_key);
		console.log('pricing_experiment_key', pricing_experiment_key);
		console.log('subscription_experiment_key', subscription_experiment_key);

		return {
			plan_experiment_key: plan_experiment_key,
			onboarding_experiment_key: onboarding_experiment_key,
			pricing_experiment_key: pricing_experiment_key,
			subscription_experiment_key: subscription_experiment_key,
		};
	} catch (error) {
		console.error('Error fetching group key:', error);
		return null;
	}
};

export const firebaseLogout = async () => {
	try {
		Mixpanel.resetIdentity();
		await auth.signOut();
		return true;
	} catch (e) {
		// console.log("error",e.message);
		return false;
	}
};

export const getExperimentKey = async (deviceId) => {
	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/user-getExperimentKey`,
			{
				deviceId,
			}
		);
		if (response.data?.status === 200 || response.data?.status === 201) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};

export const signUpUser = async (userSignUpData, scope) => {
	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/user-signupuser`,
			{
				user: userSignUpData,
				scope: scope,
			}
		);
		// console.log("signUpUser api",response.data)
		if (response.data?.status === 200 || response.data?.status === 201) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};

export const resendOtp = async (phone) => {
	// console.log("resendOtp",phone)
	try {
		const response = await postRequest(`${CLOUD_FUNCTION_URL}/user-resendotp`, {
			phone,
		});
		// console.log("resendOtp api",response.data)
		if (response.data?.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};

// TODO login user will be changed to otpless login
export const loginUser = async (phone, scope) => {
	// console.log('loginUser ', phone, scope);
	try {
		const response = await postRequest(`${CLOUD_FUNCTION_URL}/user-logindev`, {
			phone,
			scope,
		});

		console.log('loginUser api', response.data);
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};

// TODO login user will be changed to otpless login
export const verifyOtp = async (otp, phone, masterPhone) => {
	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/user-verifyotpdev`,
			{
				otp,
				phone,
				masterPhone,
			}
		);
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};

// TODO login user will be changed to otpless login
export const loginInternationalUsers = async (phone) => {
	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/user-logininternationalusers`,
			{
				phone,
			}
		);
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};

export const getUserByUserId = async (userId) => {
	// console.log("loginUser ",email,phone)
	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/user-getuserbyuserid`,
			{
				userId,
			}
		);
		// console.log("getUserByUserId api",response.data)
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};

export const getLiveStreamingReferralCode = async (userId) => {
	// console.log("getLiveStreamingReferralCode ",userId)
	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/user-getlivestreamingreferralcode`,
			{
				userId: userId,
			}
		);

		// console.log("getLiveStreamingReferralCode api",response.data)
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};

export const enrolUserToLiveClasses = async (
	userId,
	selectedPlanVariant,
	planDocId,
	paymentType
) => {
	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/user-enrollusertolivestreamingclass`,
			{
				userId: userId,
				selectedPlanVariant,
				planDocId,
				paymentType,
			}
		);
		if (response.status === 200) {
			return response.data;
		} else {
			throw new Error(response.data.message);
		}
	} catch (error) {
		throw error;
	}
};

export const getLiveStreamingPlans = async (experiment_key) => {
	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/plans-getlivestreamingplans`,
			{
				experiment_key,
			}
		);
		console.log('getLiveStreamingPlans api', response.data);
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};

export const enrollUserToLiveStreamingClass = async (
	userId,
	selectedPlanVariant
) => {
	// console.log("enrollUserToLiveStreamingClass api",userId,selectedPlanVariant);

	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/user-enrollusertolivestreamingclasswithouttrial`,
			{
				userId: userId,
				// payment_info:payment_info,
				selectedPlanVariant,
			}
		);
		// console.log("enrollUserToLiveStreamingClass api",response.data)
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};

export const enrollUserForPocCounselling = async (params) => {
	// console.log("enrollUserToLiveStreamingClass api",params);

	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/user-enrollUserForPocCounselling`,
			params
		);
		// console.log("enrollUserToLiveStreamingClass api",response.data)
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};

export const getCurrentStreamingPlanByUserid = async (
	userId,
	payment_info,
	selectedPlanVariant
) => {
	// console.log("getCurrentStreamingPlanByUserid",userId)
	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/user-getcurrentstreamingplanbyuserid`,
			{
				userId: userId,
			}
		);
		// console.log("getCurrentStreamingPlanByUserid api",response.data);
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {
		// console.log("getCurrentStreamingPlanByUserid err",e)
	}
};

// TODO change email to userID
export const checkUserExistOrNot = async (phoneNumber, scope) => {
	// console.log("checkUserExistOrNot ",phoneNumber,scope)
	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/user-checkuserexist`,
			{
				// email,
				phone: phoneNumber,
				scope: scope,
			}
		);

		// console.log("checkuserexistornot api",response.data)
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};

// TODO update user data with userid,change email to phone number
export const updateUser = async (userId, data) => {
	try {
		const response = await postRequest(`${CLOUD_FUNCTION_URL}/user-updatedev`, {
			userId,
			data,
		});
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};

// TODO marking attendace with email,change it to userId
export const markLiveStreamingAttendance = async (userId) => {
	// console.log("markLiveStreamingAttendance",userId);
	const currentDate = getCurrentDate();
	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/user-marklivestreamingattendance`,
			{
				// email:email,
				userId: userId,
				currentDate: currentDate,
			}
		);
		// console.log("markLiveStreamingAttendance res",response);
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};

export const enrollUserToTheCourse = async (
	userId,
	courseId,
	razorpay_id,
	applied_karma_points,
	batchId,
	docId,
	recordings_accessible,
	batch_start_date
) => {
	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/user-enrollusertocoursedev`,
			{
				batchId: batchId,
				courseId: courseId,
				userId: userId,
				razorpay_id: razorpay_id,
				applied_karma_points: applied_karma_points,
				docId: docId,
				recordings_accessible: recordings_accessible,
				batch_start_date: batch_start_date,
			}
		);
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};

// TODO alloting karma with email, change it to userId
export const allotKarmaPoints = async (userId) => {
	// console.log("allotKarmaPoints",userId);
	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/user-allotkarmapointsdev`,
			{
				userId: userId,
			}
		);

		// console.log("allotKarmaPoints api",response.data)
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};

// TODO replace email with userID
export const getKarmaPoints = async (userId, weekStartDate, weekEndDate) => {
	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/user-getkarmapointsdev`,
			{
				userId: userId,
				weekStartDate: weekStartDate,
				weekEndDate: weekEndDate,
			}
		);

		if (response.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};

// TODO email id with userID
export const getLiveStreamingAttendance = async (userId, data) => {
	// console.log("getLiveStreamingAttendance",userId,data);
	if (!userId || !data) {
		return null; // or handle it as needed
	}

	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/user-getlivestreamingattendancedev`,
			{
				userId,
				data,
			}
		);
		// console.log("getLiveStreamingAttendance api",response.data)
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};

export const getLeaderBoardUsers = async (page, userId) => {
	// console.log('getLeaderBoardUsers', page);
	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/user-getweeklyleaderboardusersdev`,
			{
				page,
				userId: userId,
			}
		);
		// console.log('getLeaderBoardUsers api', response.data);
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {
		console.log('getLeaderBoardUsers api err', e.message);
	}
};

export const getUpcomingClasses = async (userId) => {
	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/plans-getliveclassmultipletrackslanguagebased`,
			{
				userId,
			}
		);
		// console.log("getUpcomingClasses api",response.data)
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};

export const getNudges = async (userId) => {
	// console.log('getNudges', userId);
	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/course-getnudges`,
			{
				userId,
			}
		);
		// console.log('getNudges api', response.data);
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};

export const getFavouriteClasses = async () => {
	// console.log("getLeaderBoardUsers",weekStartDate,weekEndDate);
	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/plans-getfavouriteclasses`
		);
		// console.log('getFavouriteClasses api', response.data);
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};

export const getUpcomingCourses = async (userId) => {
	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/course-getUpcomingCourses`,
			{
				userId: userId,
			}
		);
		// console.log('getUpcomingCourses response', response.data);
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};

export const enrollUserToPlanAndCourse = async (
	userId,
	selectedPlanVariant,
	courseId,
	batchId,
	courseDocId,
	planDocId,
	applied_karma_points,
	razorpay_id,
	recordings_accessible,
	batch_end_date,
	add_on_selected
) => {
	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/user-enrollusertoplanandcourse`,
			{
				userId,
				courseId,
				batchId,
				courseDocId,
				planDocId,
				applied_karma_points,
				razorpay_id,
				recordings_accessible,
				batch_end_date,
				add_on_selected,
				selectedPlanVariant,
			}
		);
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};

export const getUpcomingCourseByCourseId = async (courseId, userId) => {
	// console.log("getUpcomingCourseByCourseId api",courseId,userId);
	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/course-getUpcomingCoursesByCourseIdDev`,
			{
				userId: userId,
				courseId: courseId,
			}
		);
		// console.log('getUpcomingCourseByCourseId response', response.data);
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};

export const getUpcomingCourseClass = async (courseTitle) => {
	// console.log('getUpcomingCourseClass', courseTitle);
	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/course-getUpcomingCourseClass`,
			{
				courseTitle: courseTitle,
			}
		);
		// console.log('getUpcomingCourseClass response', response.data);
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};

export const getCourseHomework = async (homeworkId) => {
	// console.log("getCourseHomework",homeworkId);
	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/course-getcoursehomeworkbyid`,
			{
				homeworkId: homeworkId,
			}
		);
		// console.log('getCourseHomework data', response.data);
		if (response.status === 200) {
			return response?.data;
		}
		throw response;
	} catch (e) {}
};

export const checkUserEnrolledToCourse = async (
	userId,
	courseId,
	batchId,
	scope
) => {
	// console.log('checkUserEnrolledToCourse id', userId, courseId, batchId, scope);
	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/course-checkuserenrolledtocourselatestbatch`,
			{
				userId: userId,
				courseId: courseId,
				batchId: batchId,
				scope,
			}
		);
		// console.log('checkUserEnrolledToCourse response', response?.data);
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};

// This api is checking class window time
export const getYoutubeLiveClassLink = async () => {
	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/plans-getlivestreamingclasslinkversiontwo`
		);
		// console.log("getYoutubeLiveClassLink tester",response.data)
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};

export const getZoomLiveClassLink = async (youtube_title) => {
	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/plans-getclassbyclassid`,
			{
				youtube_title,
			}
		);
		// console.log("getYoutubeLiveClassLink tester",response.data)
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};

// This api is checking class window time
export const getPocCounsellingTimeWindow = async () => {
	// console.log("getYoutubeLiveClassLink",email);
	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/plans-getpoccounsellingtimewindow`
		);
		// console.log("getpoccounsellingtimewindow tester",response.data)
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};

// This api is checking class window time
export const updateTrialStartDate = async (userId, updatedTrailStartDate) => {
	// console.log("updateTrialStartDate",userId);
	try {
		const response = await postRequest(
			`${CLOUD_FUNCTION_URL}/user-updatetrialstartdatebyuserid`,
			{
				userId: userId,
				updatedTrailStartDate: updatedTrailStartDate,
			}
		);
		// console.log("updateTrialStartDate api",response.data)
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	} catch (e) {}
};
