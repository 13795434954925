import { useDispatch } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { useEffect } from 'react';
import { router } from './routes/router';
import { fetchABExperimentGroupKey } from './services/Firebase';
import {
	addExperimentOnboardingGroupKey,
	addExperimentPlanGroupKey,
	addExperimentPricingGroupKey,
	addExperimentSubscriptionGroupKey,
} from './store/features/ABExperimentSlice';

const Router = () => {
	const dispatch = useDispatch();
	const getExperimentKeys = async () => {
		try {
			const experimentKey: any = await fetchABExperimentGroupKey();
			dispatch(addExperimentPlanGroupKey(experimentKey?.plan_experiment_key));
			dispatch(
				addExperimentOnboardingGroupKey(
					experimentKey?.onboarding_experiment_key
				)
			);
			dispatch(
				addExperimentPricingGroupKey(experimentKey?.pricing_experiment_key)
			);
			dispatch(
				addExperimentSubscriptionGroupKey(
					experimentKey?.subscription_experiment_key
				)
			);
		} catch (error) {
			console.error('Error fetching experiment group key:', error);
		}
	};
	useEffect(() => {
		getExperimentKeys();
	}, []);

	return <RouterProvider router={router}></RouterProvider>;
};

export default Router;
