// TODO new upcoming code
import React, {
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import useAuthenticatedUsers from '../../hooks/useAuthenticatedUsers';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import ratingsIcon from '../../assets/star.svg';
import liveIcon from '../../assets/new-icons/course-live-dark-icon.svg';
import durationIcon from '../../assets/new-icons/course-duration-dark-icon.svg';
import selfPacedIcon from '../../assets/new-icons/course-paced-dark-icon.svg';
import languageIcon from '../../assets/new-icons/course-language-dark-icon.svg';
import calenderIcon from '../../assets/new-icons/course-calender-dark-icon.svg';
import downloadIcon from '../../assets/new-icons/download-icon.svg';
import { ReactComponent as CLose } from '../../assets/close_blue.svg';
import { ReactComponent as Star } from '../../assets/star.svg';
import { ReactComponent as Support } from '../../assets/new-icons/whatsapp-icon.svg';
import { ReactComponent as Mute } from '../../assets/mute.svg';
import { ReactComponent as UnMute } from '../../assets/unmute.svg';
import getRecordingsBg from '../../assets/get-recordings-bg.png';
import whatsappBar from '../../assets/whatsapp-bottom-sticker.png';

import {
	checkUserExistOrNot,
	enrollUserToTheCourse,
	getUpcomingCourseByCourseId,
	getUserByUserId,
	loginInternationalUsers,
	signUpUser,
} from '../../services/Firebase';

import { storage } from '../../services/Firebase';
import './UpcomingCourseDescriptionPage.css';
import {
	generateUniqueId,
	downloadAudio,
	groupByWeek,
	isEmpty,
	LANDING_COMPONENT,
	coursesTabs,
} from '../../utils/utils';
import { Mixpanel } from '../../analytics/Mixpanel';
import Button from '../../components/button/Button';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { toast } from 'react-toastify';
import Loader from '../../components/loader/Loader';
import { COLORS } from '../../utils/colors';
import { useDispatch, useSelector } from 'react-redux';
import { getDownloadURL, ref } from 'firebase/storage';
import { FaqsCourse } from '../../utils/utils';
import FAQ from '../plans/components/FAQ';
import CourseCheckoutPage from './components/CourseCheckoutPage';
import { ReactComponent as PaymentSuccess } from '../../assets/payment-success.svg';
import { setForceRefreshOnCoursePurchase } from '../../store/features/UpcomingClassesSlice';
import CourseRegistraionForm from './components/CourseRegistraionForm';
import BackButton from '../../components-v2/buttons/BackButton';
import { events, events_action, events_page } from '../../analytics/Events';
import { addUsers } from '../../store/features/AuthSlice';
import Shimmer from '../../components-v2/shimmer/Shimmer';
import PrimaryButton from '../../components-v2/buttons/PrimaryButton';
import useVisibilityObserver from '../../hooks/useVisibilityObserver';
import BestOutOfItIconWithName from '../../components-v2/rows/BestOutOfItIconWithName';
import KnowYourInstructor from '../instructor/KnowYourInstructor';
import CustomerReview from '../../components-v2/rows/CustomerReview';
import TestimonialsVideoSlider from '../plans/components/TestimonialsVideoSlider';
import GreyAppLayout from '../GreyAppLayout';
import CourseGiftModal from './components/CourseGiftModal';
import CourseDetailsModal from './components/CourseDetailsModal';
import courseGuideIcon from './../../assets/new-icons/course-guide-icon.png';
import courseGuideCreative from './../../assets/course-guide-creative.png';

import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/free-mode';
import SecondaryButton from '../../components-v2/buttons/SecondaryButton';
import RecordingsIcon1 from '../../assets/new-icons/recordings-modal-icon-1.svg';
import RecordingsIcon2 from '../../assets/new-icons/recordings-modal-icon-2.svg';
import RecordingBanner from '../../assets/recordings_banner.png';
import CloseRecordingModelIcon from '../../assets/new-icons/close-popup.svg';
import BottomSheet from '../../components/model/BottomSheet';
import CourseBenefits from './components/CourseBenefits';
import CourseWhatYouGetItem from './components/CourseWhatYouGetItem';
import CourseCurriculum from './components/CourseCurriculum';
import { isIOS } from 'react-device-detect';

declare const window: any;

interface TriggeredEvents {
	[key: string]: boolean;
}

const sectionRanges = {
	section1: { start: 0, end: 200, section_heading: 'Course landing top video' },
	section2: { start: 0, end: 400, section_heading: 'Course landing questions' },
	section3: { start: 0, end: 500, section_heading: 'What you get' },
	section4: {
		start: 200,
		end: 800,
		section_heading: 'How will I be benefited?',
	},
	section5: {
		start: 600,
		end: 2000,
		section_heading: 'Course curriculum for you',
	},
	section6: {
		start: 1800,
		end: 2500,
		section_heading: 'What people say about us?',
	},
	section7: {
		start: 2500,
		end: 5200,
		section_heading: 'Know your Instructors',
	},
	section8: { start: 3000, end: 6000, section_heading: 'FAQs' },
	// Add other sections as needed
} as { [key: string]: { start: number; end: number; section_heading: string } };

export enum STATES {
	LOADING,
	COURSE_DESCRIPTION_PAGE,
	COURSE_CHECKOUT_PAGE,
	COURSE_SIGNUP_FORM,
	COURSE_OTP_FORM,
	PAYMENT_SUCCESS_PAGE,
	PAYMENT_FAILURE_PAGE,
}

const UpcomingCourseDescriptionPage = () => {
	const navigate = useNavigate();

	const [calendarItem, setCalendarItem] = useState<any>(null);
	const [recordingStatus, setRecordingStatus] = useState<boolean>(false);
	const [muted, setMuted] = useState(true);
	const plan_experiment_key = useSelector(
		(state: any) => state.abExperiment.data.plan_experiment_key
	);
	const onboarding_experiment_key = useSelector(
		(state: any) => state.abExperiment.data.onboarding_experiment_key
	);
	const subscription_experiment_key = useSelector(
		(state: any) => state.abExperiment.data.subscription_experiment_key
	);

	const [showRecordingsModal, setShowRecordingsModal] =
		useState<boolean>(false);

	const { courseId } = useParams();
	const reduxCalendarItem = useSelector((state: any) =>
		state.upcomingClasses?.upcomingCourse?.data?.find(
			(course: any) => course.courseId === courseId
		)
	);

	const user = useSelector((state: any) => state?.auth?.user?.data);
	const userData = useAuthenticatedUsers(true);
	const [loader, setLoader] = useState(false);
	const [state, setState] = useState<STATES>(STATES.COURSE_DESCRIPTION_PAGE);
	const [courseCurriculumUrl, setCourseCurriculumUrl] = useState<string | null>(
		null
	);

	const formattedDate = useMemo(() => {
		if (
			calendarItem &&
			calendarItem.batch &&
			calendarItem.batch[0] &&
			calendarItem.batch[0].start_date
		) {
			const { _seconds, _nanoseconds } = calendarItem?.batch[0].start_date;
			return moment(new Date(_seconds * 1000 + _nanoseconds / 1000000)).format(
				'DD MMM YY'
			);
		}
		return null;
	}, [calendarItem]);

	const formattedStartDate = useMemo(
		() =>
			moment(
				new Date(calendarItem?.batch[0].start_date._seconds * 1000)
			).format('DD MMM YY'),
		[calendarItem]
	);

	const formattedEndDate = useMemo(
		() =>
			moment(new Date(calendarItem?.batch[0].end_date._seconds * 1000)).format(
				'DD MMM YY'
			),
		[calendarItem]
	);

	const currentDateMoment = moment();

	const [imageUrl, setImageUrl] = useState('');
	const [videoUrl, setVideoUrl] = useState('');

	const [reviews, setReviews] = useState([]);
	const [showMore, setShowMore] = useState(false);
	const [showText, setShowText] = useState('Show More');

	const [selectedTab, setSelectedTab] = useState(0);

	const [selectedFAQIndex, setSelectedFAQIndex] = useState<number | null>();
	const [appliedKarmaPoints, setAppliedKarmaPoints] = useState(0);
	const dispatch = useDispatch();

	const [error, setError] = useState('');

	const [isPlayingTopVideo, setIsPlayingTopVideo] = useState(false);

	const courseSliderRef = useRef<HTMLDivElement>(null);
	const tabRefs = useRef(
		[...Array(coursesTabs.length)].map(() => React.createRef<HTMLDivElement>())
	);

	const [triggeredEvents, setTriggeredEvents] = useState<TriggeredEvents>({
		section1: false,
		section2: false,
		section3: false,
		section4: false,
		section5: false,
		section6: false,
		section7: false,
		section8: false,
		section9: false,
		section10: false,
		section11: false,
		section12: false,
		section13: false,
	});

	const supportRef = useRef<any>();
	const [whatsappRef, isVisible] = useVisibilityObserver({
		topOffset: 0,
		bottomOffset: 0,
	});

	const [lightRef, isLightVisible] = useVisibilityObserver({
		topOffset: 0,
		bottomOffset: 40,
	});

	const swiperRef = useRef<SwiperRef>(null);

	const [leadsRef, isBenefitsVisible, setIsBenefitsVisible] =
		useVisibilityObserver({
			topOffset: 0,
			bottomOffset: 100,
		});

	const [isGiftOpened, setIsGiftOpened] = useState(false);
	const [giftModal, setGiftModal] = useState(false);
	const [courseCurriculum, setCourseCurriculum] = useState<any>();

	const [detailsModal, setDetailsModal] = useState(false);

	const [name, setName] = useState('');
	const [detailsPhone, setDetailsPhone] = useState('');

	useLayoutEffect(() => {
		if (supportRef.current) {
			if (isVisible) {
				supportRef.current!.style.display = 'none';
			} else {
				supportRef.current!.style.display = 'initial';
			}
		}
	}, [isVisible]);

	useEffect(() => {
		let isScrolling: NodeJS.Timeout;
		window.onscroll = () => {
			clearTimeout(isScrolling);
			if (courseSliderRef.current && lightRef.current) {
				if (lightRef.current.getBoundingClientRect().top <= 50) {
					if (courseSliderRef.current.style.position !== 'fixed') {
						courseSliderRef.current.style.position = 'fixed';
						courseSliderRef.current.style.top = '50px';
						courseSliderRef.current.style.boxShadow =
							'1px 2px 12px 0px rgba(17, 24, 39, 0.1)';
					}
				} else {
					if (courseSliderRef.current.style.position !== 'absolute') {
						courseSliderRef.current.style.position = 'absolute';
						courseSliderRef.current.style.top = '0';
						courseSliderRef.current.style.boxShadow = 'none';
					}
				}
			}

			isScrolling = setTimeout(() => {
				tabRefs.current.forEach((tabRef, index) => {
					if (index === 0) {
						if (
							tabRef.current &&
							swiperRef.current &&
							tabRef.current.getBoundingClientRect().top <= 2000
						) {
							setSelectedTab(index);
							swiperRef.current?.swiper.slideTo(index);
						}
					} else if (index === coursesTabs.length - 1) {
						if (
							tabRef.current &&
							swiperRef.current &&
							tabRef.current.getBoundingClientRect().top <= 1000
						) {
							setSelectedTab(index);
							swiperRef.current?.swiper.slideTo(index);
						}
					} else {
						if (
							tabRef.current &&
							swiperRef.current &&
							tabRef.current.getBoundingClientRect().top <= 100
						) {
							setSelectedTab(index);
							swiperRef.current?.swiper.slideTo(index);
						}
					}
				});
			}, 150);
		};

		document.body.style.overscrollBehavior = 'none';
		return () => {
			document.body.style.overscrollBehavior = 'none';
		};
	}, [lightRef, courseSliderRef]);

	useEffect(() => {
		setIsBenefitsVisible(false);
		window.scrollTo(0, 0);
	}, [setIsBenefitsVisible]);

	useEffect(() => {
		if (
			isBenefitsVisible &&
			!isGiftOpened &&
			sessionStorage.getItem(`${calendarItem?.courseId}lead`) !== 'true' &&
			localStorage.getItem(`${calendarItem?.courseId}lead`) !== 'true'
		) {
			setGiftModal(true);
			Mixpanel.track('view_lead_magnet_bottom_sheet', {
				event_page: 'course_landing_page_bottom_sheet',
				event_action: 'viewed',
				course_name: calendarItem?.title,
			});

			sessionStorage.setItem(`${calendarItem?.courseId}lead`, 'true');
			setIsGiftOpened(true);
		}
	}, [
		calendarItem?.courseId,
		calendarItem?.title,
		isBenefitsVisible,
		isGiftOpened,
	]);

	useEffect(() => {
		calendarItem?.batch[0]?.course_curriculum &&
			setCourseCurriculum(
				groupByWeek(calendarItem?.batch[0]?.course_curriculum)
			);
	}, [calendarItem]);

	const markMixPanelEvent = (
		position: number,
		ranges: {
			[key: string]: { start: number; end: number; section_heading: string };
		}
	) => {
		const checkAndTriggerEvent = (
			sectionKey: string,
			start: number,
			end: number
		) => {
			if (
				!triggeredEvents[sectionKey] &&
				position >= start - 10 &&
				position <= end + 10
			) {
				if (ranges[sectionKey].section_heading === 'Know your instructor') {
					Mixpanel.track('view_course_landing_page_section', {
						event_action: 'viewed',
						section_heading: ranges[sectionKey].section_heading,
						event_page: events_page.course_landing_page,
						instructor_name: calendarItem?.instructors,
						course_name: calendarItem?.title,
						course_start_date: formattedDate,
					});
				} else {
					Mixpanel.track('view_course_landing_page_section', {
						event_action: 'viewed',
						section_heading: ranges[sectionKey].section_heading,
						event_page: events_page.course_landing_page,
						course_name: calendarItem?.title,
						course_start_date: formattedDate,
					});
				}

				setTriggeredEvents((prevEvents: any) => ({
					...prevEvents,
					[sectionKey]: true,
				}));
			}
		};

		Object.entries(ranges).forEach(([sectionKey, { start, end }]) => {
			checkAndTriggerEvent(sectionKey, start, end);
		});
	};

	useEffect(() => {
		const handleScroll = () => {
			markMixPanelEvent(window.scrollY, sectionRanges);
			if (window.scrollY > 280) {
				setIsPlayingTopVideo(false);
			}
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [triggeredEvents]);

	useEffect(() => {
		if (isEmpty(user) && userData?.uid) {
			getUserByUserId(userData?.uid).then((userLoginResult) => {
				dispatch(addUsers(userLoginResult?.userData));
				Mixpanel.identify(userData?.uid);
			});
		}
	}, [dispatch, user, userData]);

	useEffect(() => {
		if (calendarItem) {
			Mixpanel.track('view_course_landing_page', {
				event_action: 'viewed',
				course_name: calendarItem?.title,
				course_date: formattedDate,
				instructor_name: calendarItem?.instructors,
				event_page: events_page.course_landing_page,
				course_type: calendarItem?.batch?.[0]?.tag_name,
			});
		}
	}, [calendarItem, formattedDate]);

	useEffect(() => {
		// Set calendarItem from Redux if it's available
		if (reduxCalendarItem) {
			setCalendarItem(reduxCalendarItem);
		} else if (calendarItem === null && courseId) {
			setLoader(true);
			getUpcomingCourseByCourseId(courseId, userData?.uid)
				.then((result: any) => {
					setCalendarItem(result?.data?.[0]);
					setLoader(false);
				})
				.catch((err: any) => {
					console.error(err);
					setLoader(false);
				});
		}
	}, [reduxCalendarItem, calendarItem, courseId, userData?.uid]);

	useEffect(() => {
		(async () => {
			try {
				if (calendarItem?.top_video) {
					const videoRef = ref(storage, calendarItem?.top_video);
					const videoUrl = await getDownloadURL(videoRef);
					setVideoUrl(videoUrl);
				}

				if (calendarItem?.thumbnail) {
					const imageRef = ref(storage, calendarItem?.thumbnail);
					const imageUrl = await getDownloadURL(imageRef);
					setImageUrl(imageUrl);
				}

				if (calendarItem?.batch[0]?.course_schedule_pdf) {
					const downloadRef = ref(
						storage,
						calendarItem?.batch[0]?.course_schedule_pdf
					);
					const downloadUrl = await getDownloadURL(downloadRef);
					setCourseCurriculumUrl(downloadUrl);
				}
			} catch (error) {
				console.error('Error fetching image:', error);
			}
		})();
	}, [calendarItem]);

	const handleCourseCurriculumDownload = async () => {
		try {
			if (courseCurriculumUrl) {
				const response = await fetch(courseCurriculumUrl, {
					method: 'GET',
				});
				if (!response.ok) {
					throw new Error('Failed to fetch image');
				}
				const blob = await response.blob();

				const link = document.createElement('a');
				link.target = '_blank';
				link.href = URL.createObjectURL(blob);
				link.download = calendarItem?.title;

				document.body.appendChild(link);
				link.click();

				document.body.removeChild(link);
				URL.revokeObjectURL(link.href);
			}
		} catch (error) {
			console.error('Error downloading the image:', error);
		}
	};

	function loadScript(src: any) {
		return new Promise((resolve) => {
			const script = document.createElement('script');
			script.src = src;
			script.onload = () => {
				resolve(true);
			};
			script.onerror = () => {
				resolve(false);
			};
			document.body.appendChild(script);
		});
	}

	async function payNowClickHandler(
		price: string,
		applied_karma_points: number,
		recordings_status: boolean
	) {
		setAppliedKarmaPoints(applied_karma_points);
		setRecordingStatus(recordings_status);
		if (state === STATES.PAYMENT_FAILURE_PAGE) {
			Mixpanel.track('click_retry_course_payment', {
				event_action: 'clicked',
			});
			setState(STATES.COURSE_CHECKOUT_PAGE);
		}

		const res = await loadScript(
			'https://checkout.razorpay.com/v1/checkout.js'
		);

		if (!res) {
			toast.error(`Razorpay SDK failed to load. Are you online?`, {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 2000,
				hideProgressBar: true,
			});
			return;
		}
		// TODO change it
		const parsedPrice = parseFloat(price);
		// const parsedPrice = 1;

		const docId = generateUniqueId(
			user?.uid || userData?.uid,
			calendarItem?.courseId,
			calendarItem?.batch?.[0]?.batchId
		);

		enrollUserToTheCourse(
			user?.uid || userData?.uid,
			calendarItem?.courseId,
			'',
			applied_karma_points,
			calendarItem?.batch?.[0]?.batchId,
			docId,
			recordings_status,
			formattedEndDate
		).then((result: any) => {
			console.log('enrollUserToTheCourse', result);
		});

		const options = {
			key: 'rzp_live_kAJsHAyh9zWVtC', // Enter the Key ID generated from the Dashboard
			amount: parsedPrice * 100,
			currency: 'INR',
			name: calendarItem?.title + ' - Seek',
			description: `Course Enrollment Fee-${docId} `,
			image:
				'https://firebasestorage.googleapis.com/v0/b/seek-c3955.appspot.com/o/seek-logo-icon-blue.png?alt=media&token=e73fb5ae-3f19-42d0-afe8-1b76a89cf66f',
			handler: async function (response: any) {
				if (response.razorpay_payment_id) {
					setState(STATES.PAYMENT_SUCCESS_PAGE);
					dispatch(setForceRefreshOnCoursePurchase(true));
					try {
						Mixpanel.track('view_course_payment_successful', {
							event_action: 'viewed',
							course_name: calendarItem?.title,
							userId: user?.uid || userData?.uid,
							event_page: 'course_payment_page',
						});
					} catch (e) {}
				} else {
					setState(STATES.PAYMENT_FAILURE_PAGE);
					toast.error(
						`Your payment of ${parsedPrice} has been failed due to some technical reasons.`,
						{
							position: toast.POSITION.TOP_CENTER,
							autoClose: 2000,
							hideProgressBar: true,
						}
					);

					try {
						Mixpanel.track('view_course_payment_failed', {
							event_action: 'viewed',
							phone: userData?.phoneNumber || userData?.phone,
							event_page: 'course_payment_page',
						});
					} catch (e) {}
				}
			},
			prefill: {
				name: user?.name || userData?.name,
				contact:
					user?.phoneNumber ||
					user?.phone ||
					userData?.phoneNumber ||
					userData?.phone ||
					'9959637700',
			},
			theme: { color: COLORS.PRIMARY },
			modal: {
				ondismiss: function (response: any) {
					try {
						Mixpanel.track('view_course_payment_failed', {
							event_action: 'viewed',
							phone: userData?.phoneNumber || userData?.phone,
							event_page: 'course_payment_page',
						});
					} catch (e) {}

					toast.error(
						`Your payment of ${parsedPrice} has been failed due to some technical reasons.`,
						{
							position: toast.POSITION.TOP_CENTER,
							autoClose: 2000,
							hideProgressBar: true,
						}
					);
				},
			},
		};

		const paymentObject = (window as any).Razorpay(options);
		paymentObject.open();
	}

	const onJoinWhatsAppsCommunityHandler = async () => {
		try {
			let whatsappGroupLink =
				'https://chat.whatsapp.com/DjjW2D53bOsK8aZ7YpAFZI';
			Mixpanel.track('click_join_whatsapp_course_group', {
				event_action: 'clicked',
				context_user_id: user?.uid,
				course_name: calendarItem?.title,
				phone: userData?.phoneNumber || userData?.phone,
			});
			window.open(
				calendarItem?.whatsapp_group_link || whatsappGroupLink,
				'_blank'
			);
			setTimeout(() => {
				if (user?.source === 'WEB') {
					navigate('/home');
				}
			}, 1000);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		Mixpanel.startRecording();

		return () => Mixpanel.stopRecording();
	}, []);

	const proceedFromCourseDescriptionPage = async () => {
		Mixpanel.track('click_enroll_now_course_landing_page', {
			event_action: 'clicked',
			event_page: events_page.course_landing_page,
			course_name: calendarItem?.title,
		});

		if (currentDateMoment.isAfter(formattedDate)) {
			// Course already started
			return;
		}

		if (!isEmpty(user) || userData) {
			setState(STATES.COURSE_CHECKOUT_PAGE);
		} else {
			Mixpanel.track('view_register_with_seek', {
				event_action: 'viewed',
				event_page: 'register_with_seek',
				course_name: calendarItem?.title,
			});
			setState(STATES.COURSE_SIGNUP_FORM);
		}
	};

	useEffect(() => {
		Mixpanel.startRecording();

		return () => Mixpanel.stopRecording();
	}, []);

	const onFAQClickHandler = (index: number) => {
		if (selectedFAQIndex !== index) {
			try {
				Mixpanel.track('click_course_landing_page_faqs', {
					event_action: 'clicked',
					faq_heading: FaqsCourse[index].q,
					event_page: events_page.course_landing_page,
				});
			} catch (e) {}
		}
		setSelectedFAQIndex((prevState) => (prevState === index ? null : index));
	};

	const onBackArrowClick = () => {
		if (state === STATES.COURSE_DESCRIPTION_PAGE) {
			if (!userData || isEmpty(user)) {
				window.location.href = 'https://seekdaily.live';
				navigate('/');
			} else {
				navigate('/courses');
			}
		}

		setState((prevState) => {
			switch (prevState) {
				case STATES.COURSE_CHECKOUT_PAGE:
					return STATES.COURSE_DESCRIPTION_PAGE;
				case STATES.COURSE_SIGNUP_FORM:
					return STATES.COURSE_DESCRIPTION_PAGE;
				case STATES.COURSE_OTP_FORM:
					return STATES.COURSE_SIGNUP_FORM;
				default:
					return prevState;
			}
		});
	};

	const onFormSubmitHandler = async (fullName: string, phoneNumber: string) => {
		const checkUserExistOrNotResponse = await checkUserExistOrNot(
			phoneNumber,
			'COURSE'
		);
		if (
			checkUserExistOrNotResponse?.status === 409 &&
			checkUserExistOrNotResponse?.message ===
				'User already exists. Show otp screen'
		) {
			// login user directly without otp
			try {
				const userLoginResult = await loginInternationalUsers(phoneNumber);
				console.log('loginInternationalUsers', userLoginResult);
				if (userLoginResult?.status === 200) {
					console.log('login successful if', userLoginResult.userData?.uid);
					Mixpanel.identify(userLoginResult.userData?.uid);
					Mixpanel.track(events.view_login_successful, {
						event_page: events_page.sign_up,
						event_action: events_action.viewed,
						response: {
							phone: phoneNumber,
						},
					});
					const user = {
						phone: userLoginResult?.userData?.phone,
						name: userLoginResult?.userData?.name,
						phoneNumber: userLoginResult?.userData?.phoneNumber,
						uid: userLoginResult.userData?.uid,
						// isProfileCompleted: isProfileCompleted,
					};

					dispatch(addUsers(userLoginResult.userData));
					window.localStorage.setItem('user', JSON.stringify(user));
					setState(STATES.COURSE_CHECKOUT_PAGE);
					// setLoader(false);
				} else {
					// setLoader(false);
					console.log('login successful else');
					setError('Please enter the correct OTP');
					Mixpanel.track('otp_incorrect', {
						event_page: events_page.otp_screen,
						event_action: events_action.viewed,
					});
				}
			} catch (e) {}
		} else {
			// signup user directly without otp.
			const nameArray = fullName.split(' ');
			const firstName = nameArray[0];
			const lastName = nameArray.slice(1).join(' ');

			const formDataMergedFromFirebaseAndSingUpForm = {
				userPartOfWhatsappCommunity: false,
				name: fullName,
				phone: phoneNumber,
				source: 'WEB',
				experiment_keys: {
					plan_experiment_key,
					onboarding_experiment_key,
					subscription_experiment_key,
				},
				trialStartDate: moment().format('D MMM YYYY'),
			};
			try {
				const userSignUpResult = await signUpUser(
					formDataMergedFromFirebaseAndSingUpForm,
					'COURSE'
				);
				if (
					userSignUpResult.status === 201 ||
					userSignUpResult.status === 200
				) {
					setLoader(false);
					Mixpanel.identify(userSignUpResult.userId);
					Mixpanel.people.set({
						$id: userSignUpResult.userId,
						$first_name: firstName,
						$last_name: lastName,
						$plan_experiment_key: plan_experiment_key,
						$onboarding_experiment_key: onboarding_experiment_key,
						$name: fullName,
						$phone: phoneNumber,
					});
					setLoader(false);
					Mixpanel.track('SignUp', userSignUpResult.userData);
					window.localStorage.setItem(
						'user',
						JSON.stringify(userSignUpResult.userData)
					);
					dispatch(addUsers(userSignUpResult.userData));
					setState(STATES.COURSE_CHECKOUT_PAGE);
				} else {
					setLoader(false);
				}
			} catch (e) {}
		}
	};

	const chatWhatsAppsHandler = async (sticker: boolean) => {
		try {
			const event_name = sticker ? 'click_whatsapp_chat' : 'click_talk_to_us';
			Mixpanel.track(event_name, {
				event_action: 'clicked',
				course_name: calendarItem?.title,
				event_page: events_page.course_landing_page,
			});
			const whatsappGroupLink = 'https://wa.me/9555434844';
			window.open(whatsappGroupLink, '_blank');
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const thumbnailRef = useRef<any>();
	const introRef = useRef<any>();

	useEffect(() => {
		if (introRef.current)
			introRef.current.setAttribute(
				'controlsList',
				'nodownload noremoteplayback noplaybackrate'
			);
	}, [introRef]);

	useEffect(() => {
		console.log('showMore', showMore);
		if (showMore) {
			setReviews(calendarItem?.testimonial_text);
			setShowText('Show Less');
		} else {
			setShowText('Show More');
			setReviews(calendarItem?.testimonial_text?.slice(0, 2));
		}
	}, [showMore, calendarItem]);

	const onClickShowMoreReviews = () => {
		setShowMore((prevState) => !prevState);
		Mixpanel.track('click_show_more_reviews', {
			event_action: 'clicked',
			event_page: events_page.course_landing_page,
		});
	};

	if (loader) {
		return <Loader />;
	} else if (state === STATES.PAYMENT_SUCCESS_PAGE) {
		return calendarItem?.title ? (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					marginTop: '154px',
					position: 'relative',
				}}>
				<PaymentSuccess width={120} height={120} />
				<span className={'course-payment-success-hurray'}>
					Payment Successful 🎉
				</span>
				<span className={'course-payment-success-plan-description'}>
					{`You have successfully enrolled in `}
					<strong
						style={{
							color: '#2A2A2A',
							fontWeight: '500',
							fontFamily: 'Poppins-Medium',
						}}>
						{calendarItem?.title}
					</strong>
					{`. We will share further details with you over WhatsApp.`}
				</span>
				<PrimaryButton
					isSticky={false}
					style={{
						alignSelf: 'center',
						width: 'auto',
						height: '40px',
						marginTop: '24px',
						borderRadius: '8px',
						background:
							'var(--Primary-Gradient, linear-gradient(106deg, #4462EF 32.65%, #1B3DD8 105.18%))',
					}}
					onClick={onJoinWhatsAppsCommunityHandler}>
					Join WhatsApp Group
				</PrimaryButton>
			</div>
		) : (
			<></>
		);
	} else if (state === STATES.PAYMENT_FAILURE_PAGE) {
		return calendarItem?.price ? (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					marginTop: '50%',
					position: 'relative',
				}}>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='98'
					height='88'
					viewBox='0 0 98 88'
					fill='none'>
					<path
						d='M40.5607 5.27521C41.4261 3.80618 42.6599 2.58845 44.1402 1.74244C45.6204 0.896423 47.2959 0.451416 49.0009 0.451416C50.7059 0.451416 52.3813 0.896423 53.8616 1.74244C55.3419 2.58845 56.5757 3.80618 57.4411 5.27521L77.0745 38.9413L96.7087 72.6026C97.5586 74.0753 98.004 75.7466 98 77.447C97.9959 79.1473 97.5426 80.8164 96.6858 82.2851C95.829 83.7539 94.5992 84.9701 93.121 85.8105C91.6429 86.6509 89.9688 87.0857 88.2685 87.0708H9.73245C8.0319 87.0859 6.35759 86.6511 4.8792 85.8106C3.4008 84.9701 2.17086 83.7537 1.31401 82.2848C0.457158 80.8158 0.00385102 79.1464 2.4429e-05 77.4458C-0.00380216 75.7452 0.441987 74.0738 1.29222 72.6009L20.9264 38.9413L40.5607 5.27521Z'
						fill='#FD2727'
					/>
					<path
						d='M45.503 53.5448L44.4848 38.462C44.2941 35.523 44.1988 33.4134 44.1988 32.1333C44.1092 30.6455 44.6058 29.1818 45.5822 28.0557C46.0511 27.5676 46.6183 27.1846 47.2463 26.9322C47.8743 26.6798 48.5487 26.5636 49.225 26.5914C51.0489 26.5914 52.2683 27.216 52.8834 28.4651C53.5845 30.1755 53.8995 32.0195 53.8059 33.8656C53.8043 35.2751 53.7299 36.6835 53.5828 38.0853L52.2149 53.6109C52.1571 55.0735 51.8337 56.5133 51.2605 57.8601C51.0544 58.3223 50.713 58.7113 50.2814 58.9755C49.8497 59.2397 49.348 59.3667 48.8426 59.3399C48.3455 59.3775 47.8491 59.2598 47.4217 59.0033C46.9943 58.7467 46.6571 58.3638 46.4565 57.9075C45.9094 56.5135 45.5873 55.0415 45.5021 53.5464L45.503 53.5448ZM49.033 74.2649C47.7889 74.2831 46.582 73.84 45.6451 73.0213C45.1579 72.5874 44.7747 72.0493 44.5241 71.4469C44.2736 70.8445 44.1621 70.1933 44.198 69.5419C44.1877 68.9228 44.3052 68.3083 44.5432 67.7368C44.7813 67.1652 45.1347 66.6489 45.5814 66.2202C46.0237 65.7742 46.5522 65.4227 47.1346 65.1873C47.717 64.9519 48.3412 64.8373 48.9693 64.8507C49.6018 64.8392 50.2303 64.9544 50.8176 65.1896C51.4049 65.4248 51.9392 65.7752 52.389 66.2202C52.8438 66.6443 53.2047 67.159 53.4485 67.7311C53.6923 68.3032 53.8135 68.9201 53.8043 69.5419C53.8373 70.1893 53.7259 70.8359 53.4783 71.435C53.2307 72.034 52.8531 72.5706 52.3727 73.0058C51.457 73.8304 50.2643 74.2799 49.0322 74.2649'
						fill='white'
					/>
				</svg>
				<span
					style={{
						color: '#3A3A3A',
						textAlign: 'center',
						fontFamily: 'Poppins-SemiBold',
						fontSize: '24px',
						fontStyle: 'normal',
						fontWeight: 600,
						lineHeight: 'normal',
						marginTop: '32px',
					}}>
					Payment Failed
				</span>
				<span
					className={'payment-success-plan-description'}
					style={{
						marginTop: '16px',
					}}>{`Your payment of INR ${
					calendarItem?.batch?.[0]?.offers_valid
						? calendarItem?.batch?.[0]?.course_offer_price
						: calendarItem?.batch?.[0]?.price
				} has been failed due to some technical reasons.`}</span>

				<CLose
					onClick={navigate.bind(null, -1)}
					style={{ position: 'absolute', top: -120, right: 24 }}
					width={36}
					height={36}
				/>

				<Button
					isSticky={false}
					style={{
						// backgroundColor: daysToExpire < 0 ? COLORS.DARK_GREY : COLORS.PRIMARY,
						alignSelf: 'center',
						background:
							'var(--primary_lr_grad, linear-gradient(106deg, #534DE0 32.65%, #4641A8 105.18%))',
					}}
					onClick={payNowClickHandler.bind(
						null,
						calendarItem?.batch?.[0]?.offers_valid
							? calendarItem?.batch?.[0]?.course_offer_price
							: calendarItem?.batch?.[0]?.price,
						appliedKarmaPoints,
						recordingStatus
					)}
					label={'Retry Payment'}
				/>
			</div>
		) : (
			<></>
		);
	} else if (state === STATES.COURSE_CHECKOUT_PAGE) {
		return (
			<CourseCheckoutPage
				imageUrl={imageUrl}
				userId={user?.uid || userData?.uid}
				totalKarma={user?.total_karma}
				courseItem={calendarItem}
				recordingStatus={recordingStatus}
				setRecordingStatus={setRecordingStatus}
				payNowClickHandler={payNowClickHandler}
				onBackArrowClick={onBackArrowClick}
			/>
		);
	} else if (state === STATES.COURSE_SIGNUP_FORM) {
		return (
			<GreyAppLayout>
				<CourseRegistraionForm
					loading={loader}
					onFormSubmitHandler={onFormSubmitHandler}
					setState={setState}
					onBackArrowClick={onBackArrowClick}
					user={user}
					phone={detailsPhone}
					name={name}
					course_name={calendarItem?.title}
				/>
			</GreyAppLayout>
		);
	}
	// else if (state === STATES.COURSE_OTP_FORM) {
	//     return (
	//         <CourseOtpForm
	//             otpError={error}
	//             page={'course_registration_form'}
	//             onBackArrowClick={onBackArrowClick}
	//             onOtpVerifyHandler={onOtpVerifyHandler}
	//             mobileNumber={phone}
	//         />
	//     );
	// }

	const handleHideGiftModal = () => {
		setGiftModal(false);
	};

	const handleHideDetailsModal = () => {
		setDetailsModal(false);
		setGiftModal(false);
	};

	const handleGiftDownloadClick = () => {
		Mixpanel.track('click_download_for_free', {
			event_page: 'course_landing_page_bottom_sheet',
			event_action: 'clicked',
			course_name: calendarItem?.title,
		});
		Mixpanel.track('view_fill_details_lead_magnet', {
			event_page: 'course_landing_page_bottom_sheet',
			event_action: 'viewed',
			course_name: calendarItem?.title,
		});
		handleHideGiftModal();
		setDetailsModal(true);
	};

	const handleDetailsDownloadClick = () => {
		downloadAudio(calendarItem.lead_audio_url, 'seek-meditation');
		localStorage.setItem(`${calendarItem?.courseId}lead`, 'true');
	};

	const handleVideoClick = () => {
		if (isPlayingTopVideo) {
			introRef.current.pause();
			setIsPlayingTopVideo(false);
		} else {
			introRef.current.play();
			setIsPlayingTopVideo(true);
		}
	};

	return (
		<>
			<div className='course-description-container dark'>
				<div
					style={{
						width: '100%',
						maxWidth: '400px',
						position: 'fixed',
						padding: '16px',
						top: '0',
						zIndex: 1000000,
						background: '#1b1625',
					}}>
					<div style={{ width: '343px', margin: '0 auto' }}>
						<BackButton isDark onClick={navigate.bind(null, -1)} />
					</div>
				</div>

				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						alignSelf: 'center',
						width: '100%',
						borderRadius: '12px',
						maxWidth: '343px',
						marginTop: '74px',
						position: 'relative',
					}}>
					{!isIOS && videoUrl ? (
						muted ? (
							<Mute
								onClick={() => setMuted(false)}
								width={20}
								height={20}
								style={{
									position: 'absolute',
									top: '8px',
									left: '8px',
									color: 'white',
									zIndex: '2',
								}}
							/>
						) : (
							<UnMute
								onClick={() => setMuted(true)}
								width={20}
								height={20}
								style={{
									position: 'absolute',
									top: '8px',
									left: '8px',
									color: 'white',
									zIndex: '2',
								}}
							/>
						)
					) : (
						<></>
					)}

					<Shimmer style={{ height: '190px' }} />
					{videoUrl ? (
						<video
							ref={introRef}
							controls
							onClick={handleVideoClick}
							autoPlay={true}
							loop={true}
							src={videoUrl}
							playsInline
							muted={muted}
							style={{
								width: '100%',
								display: 'block',
								position: 'absolute',
							}}></video>
					) : imageUrl ? (
						<img
							src={imageUrl}
							ref={thumbnailRef}
							alt=''
							style={{
								position: 'absolute',
							}}
							className={'course-description-image-banner'}
						/>
					) : (
						<></>
					)}
				</div>

				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'start',
						width: '344px',
						marginTop: '24px',
						alignSelf: 'center',
						justifyContent: 'space-between',
					}}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							width: '100%',
						}}>
						<span
							style={{
								color: '#ffffff',
								fontFamily: 'Gordita-Medium',
								fontSize: '20px',
								fontStyle: 'normal',
								fontWeight: 500,
								lineHeight: 'normal',
								marginRight: 'auto',
								display: 'inline',
							}}>
							{calendarItem?.title}
						</span>
						<span
							style={{
								color: '#ffffff',
								fontFamily: 'Gordita-Medium',
								fontSize: '12px',
								fontStyle: 'normal',
								alignSelf: 'center',
								fontWeight: 500,
								marginTop: '4px',
								lineHeight: '16px', // 133.333%
							}}>
							{calendarItem?.course_ratings?.rating || 5}
						</span>
						<Star
							width={14}
							height={14}
							style={{ margin: '0 4px', alignSelf: 'center' }}
						/>
						<span
							style={{
								color: '#ffffff',
								fontFamily: 'Gordita-Medium',
								fontSize: '12px',
								fontStyle: 'normal',
								alignSelf: 'center',
								fontWeight: 500,
								marginTop: '4px',
								lineHeight: '16px',
							}}>
							{calendarItem?.course_ratings?.count
								? `(${calendarItem?.course_ratings?.count})`
								: 2360}
						</span>
					</div>

					<div
						style={{ marginTop: '16px', marginBottom: '8px' }}
						className='course-cover-info-date'>
						<img src={calenderIcon} alt='' />
						<p>
							{formattedStartDate} - {formattedEndDate}
						</p>
					</div>
					<div
						style={{ width: '100%', marginBottom: '16px' }}
						className='course-cover-info-details'>
						<div className='course-cover-info-details-item'>
							<img
								style={{
									margin:
										calendarItem?.courseFormat === 'Self_Paced'
											? '-4px 4px 0 4px'
											: '-4px 0 0 0',
								}}
								src={
									calendarItem?.courseFormat === 'Self_Paced'
										? selfPacedIcon
										: liveIcon
								}
								alt=''
							/>
							<p>
								{calendarItem?.courseFormat === 'Self_Paced'
									? 'Self paced'
									: calendarItem?.batch[0]?.class_time + ' IST' || '9:30 PM'}
							</p>
						</div>
						<div className='course-cover-info-details-divider'></div>
						<div className='course-cover-info-details-item'>
							<img src={durationIcon} alt='' />
							<p>{calendarItem?.duration}</p>
						</div>
						<div className='course-cover-info-details-divider'></div>
						<div className='course-cover-info-details-item'>
							<img src={languageIcon} alt='' />
							<p>{calendarItem?.language}</p>
						</div>
					</div>
					<div
						style={{
							backgroundColor: 'rgba(184, 172, 246, 0.15)',
							padding: '12px',
							paddingBottom: '0',
							borderRadius: '12px',
							width: '100%',
							marginBottom: '12px',
						}}>
						<p
							style={{
								fontFamily: 'Gordita-Medium',
								fontSize: '16px',
								color: '#ffffff',
							}}>
							Course is for <span style={{ color: '#FFD39E' }}>you</span>, if
							you said <span style={{ color: '#FFD39E' }}>'yes'</span> to any of
							the questions below.
							{calendarItem?.course_questions &&
								calendarItem?.course_questions.map(
									(item: any, index: number) => (
										<BestOutOfItIconWithName
											key={index}
											index={index}
											name={item}
											textColor='#e4e4e4'
										/>
									)
								)}
						</p>
					</div>

					<div
						ref={lightRef}
						style={{
							width: '402px',
							marginTop: !calendarItem?.refund_available ? '16px' : '0px',
							position: 'relative',
							left: '-29px',
							paddingLeft: '29px',
							borderRadius: '12px 12px 0 0',
						}}>
						<div ref={courseSliderRef} className='course-slider-container'>
							<Swiper
								ref={swiperRef}
								className='course-slider'
								freeMode={true}
								slidesPerView={'auto'}
								slidesOffsetAfter={0}
								spaceBetween={20}
								modules={[FreeMode]}>
								{coursesTabs.map((tab, index) => {
									return (
										<SwiperSlide key={index}>
											<div
												onClick={() => {
													swiperRef.current?.swiper.slideTo(index);
													if (tabRefs.current[index].current) {
														tabRefs.current[index].current!.scrollIntoView();
													}
													setSelectedTab(index);
													Mixpanel.track('click_navigation_bar', {
														event_page: events_page.course_landing_page,
														event_action: 'viewed',
														navigate_on: coursesTabs[index],
													});
												}}
												className={`course-slider-tab ${
													selectedTab === index && 'active'
												}`}>
												<p className='course-slider-tab-title'>{tab}</p>
											</div>
										</SwiperSlide>
									);
								})}
							</Swiper>
						</div>
						<div style={{ marginTop: '68px' }}>
							{calendarItem?.batch[0].what_you_get &&
							calendarItem?.batch[0].what_you_get.length ? (
								<>
									<div
										className='page-section-title-scroll'
										ref={tabRefs.current[0]}></div>
									<div className={'course-page-section-title'}>
										What you get
										<div
											style={{
												height: '1px',
												background:
													'linear-gradient(to right, #a4a4a4, transparent)',
												width: '200px',
												marginLeft: '8px',
											}}></div>
									</div>
									<div
										style={{ width: '344px', marginTop: '12px' }}
										className='course-what-you-get'>
										<CourseWhatYouGetItem
											item={calendarItem?.batch[0].what_you_get[0]}
											divider={true}
										/>
										<CourseWhatYouGetItem
											item={calendarItem?.batch[0].what_you_get[1]}
											divider={calendarItem?.batch[0].course_guide}
										/>
										{calendarItem?.batch[0].course_guide && (
											<div className='course-what-you-get-item'>
												<div className='course-what-you-get-item-icon'>
													<img src={courseGuideIcon} alt='' />
												</div>
												<div className='course-what-you-get-item-info'>
													<p className='course-what-you-get-item-info-title'>
														Lifetime Guide -{' '}
														<span style={{ color: '#FAA53D' }}>free</span>
													</p>
													<p className='course-what-you-get-item-info-subtitle'>
														Delivered at your home, for free
													</p>
												</div>
												<img
													style={{
														width: '80px',
														height: '80px',
														transform: 'translate(12px, 12px)',
													}}
													src={courseGuideCreative}
													alt=''
												/>
											</div>
										)}
									</div>
								</>
							) : (
								<></>
							)}
							{!!calendarItem?.recording_offer_price && (
								<div
									style={{ width: '344px', marginTop: '12px' }}
									className='course-get-recordings'>
									<img src={getRecordingsBg} alt='' />
									<div>
										<p className='course-get-recordings-title'>
											Get class recordings instantly, after course completion
										</p>
										<div className='course-get-recordings-amount'>
											<div>
												<p className='course-get-recordings-offer'>
													₹{calendarItem?.recording_offer_price}
												</p>
												<p className='course-get-recordings-actual'>
													₹{calendarItem?.recording_actual_price}
												</p>
											</div>
											<div
												onClick={() => {
													setRecordingStatus(!recordingStatus);
													if (recordingStatus) {
														Mixpanel.track('click_added_recording', {
															event_action: 'clicked',
															event_page: events_page.course_landing_page,
															course_name: calendarItem?.title,
															phone: userData?.phone || userData?.number,
														});
													} else {
														Mixpanel.track('click_add_recording', {
															event_action: 'clicked',
															event_page: events_page.course_landing_page,
															course_name: calendarItem?.title,
															phone: userData?.phone || userData?.number,
														});
													}
												}}
												className='course-get-recordings-button'>
												<span>{recordingStatus ? '×' : '+'}</span>{' '}
												{recordingStatus ? 'Added' : 'Add my recording'}
											</div>
										</div>
									</div>
								</div>
							)}
							<div
								className='page-section-title-scroll'
								ref={tabRefs.current[1]}></div>
							{!!calendarItem?.course_benefits && (
								<>
									<div className={'course-page-section-title'}>
										How will I be benefited?
										<div
											style={{
												height: '1px',
												background:
													'linear-gradient(to right, #a4a4a4, transparent)',
												width: '150px',
												marginLeft: '8px',
											}}></div>
									</div>
									<CourseBenefits benefits={calendarItem?.course_benefits} />
								</>
							)}
						</div>
						<div
							className='page-section-title-scroll'
							ref={tabRefs.current[2]}></div>
						<div className={'course-page-section-title'}>
							Course Curriculum for you
							<div
								style={{
									height: '1px',
									background: 'linear-gradient(to right, #a4a4a4, transparent)',
									width: '150px',
									marginLeft: '8px',
								}}></div>
						</div>
						{!!calendarItem?.batch[0]?.course_schedule_pdf && (
							<div
								onClick={handleCourseCurriculumDownload}
								className='course-curriculum-download'>
								<div className='course-curriculum-download-button'>
									<img src={downloadIcon} alt='' />
									<p>Download schedule</p>
								</div>
							</div>
						)}
						{courseCurriculum && (
							<CourseCurriculum
								curriculum={courseCurriculum}></CourseCurriculum>
						)}
						<div
							className='page-section-title-scroll'
							ref={tabRefs.current[3]}></div>
						<div ref={leadsRef} className={'course-page-section-title'}>
							What people say about us?
							<div
								style={{
									height: '1px',
									background: 'linear-gradient(to right, #a4a4a4, transparent)',
									width: '150px',
									marginLeft: '8px',
								}}></div>
						</div>
						{calendarItem?.testimonial_videos &&
						calendarItem?.testimonial_videos.length ? (
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
								}}>
								<TestimonialsVideoSlider
									dark
									pageName={'course_desciption_page'}
									style={{
										width: '360px',
										height: '220px',
										marginTop: '24px',
									}}
									testimonials={calendarItem?.testimonial_videos}
									naturalSlideHeight={256}
									naturalSlideWidth={240}
									component_name={LANDING_COMPONENT.TESTIMONIAL}
								/>
							</div>
						) : (
							<></>
						)}
						<div style={{ width: '344px', marginTop: '20px' }}>
							{calendarItem?.testimonial_text &&
								reviews?.map((review: any, index: number) => (
									<CustomerReview
										dark
										page='course'
										count={reviews?.length}
										index={index}
										imageSrc={review.imageSrc}
										name={review.module}
										period={review.name}
										bio={review.text}
										rating={5}
									/>
								))}
						</div>
						{calendarItem?.testimonial_text?.length > 2 ? (
							<div
								className={'horizontal-container'}
								style={{
									width: '343px',
									alignItems: 'center',
									justifyContent: 'start',
									marginTop: '14px',
									marginBottom: '24px',
								}}
								onClick={onClickShowMoreReviews}>
								<span
									style={{
										color: '#fff',
										fontFamily: 'Gordita-Medium',
										fontSize: '12px',
										fontStyle: 'normal',
										textDecoration: 'underline',
										fontWeight: 500,
										alignSelf: 'start',
										textAlign: 'start',
										lineHeight: '18px', // or "150%"
									}}>
									{showText}
								</span>
								{showMore ? (
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 20 20'
										fill='none'>
										<path
											d='M10.0002 9.00003L13.4202 12.141C13.5503 12.2699 13.7261 12.3422 13.9092 12.3422C14.0923 12.3422 14.2681 12.2699 14.3982 12.141C14.4625 12.0778 14.5135 12.0023 14.5483 11.9192C14.5831 11.836 14.6011 11.7467 14.6011 11.6565C14.6011 11.5664 14.5831 11.4771 14.5483 11.3939C14.5135 11.3107 14.4625 11.2353 14.3982 11.172L10.4902 7.34203C10.3598 7.21336 10.1839 7.14121 10.0007 7.14121C9.81749 7.14121 9.64164 7.21336 9.51121 7.34203L5.60321 11.172C5.53896 11.2353 5.48794 11.3107 5.45311 11.3939C5.41828 11.4771 5.40035 11.5664 5.40035 11.6565C5.40035 11.7467 5.41828 11.836 5.45311 11.9192C5.48794 12.0023 5.53896 12.0778 5.60321 12.141C5.73335 12.2699 5.90908 12.3422 6.09221 12.3422C6.27534 12.3422 6.45108 12.2699 6.58121 12.141L10.0002 9.00003Z'
											fill='#ffffff'
										/>
									</svg>
								) : (
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='20'
										height='20'
										viewBox='0 0 20 20'
										fill='none'>
										<path
											d='M10 11L13.42 7.859C13.5501 7.73015 13.7259 7.65787 13.909 7.65787C14.0921 7.65787 14.2679 7.73015 14.398 7.859C14.4622 7.92227 14.5133 7.9977 14.5481 8.08087C14.5829 8.16405 14.6009 8.25333 14.6009 8.3435C14.6009 8.43368 14.5829 8.52295 14.5481 8.60613C14.5133 8.68931 14.4622 8.76473 14.398 8.828L10.49 12.658C10.3596 12.7867 10.1837 12.8588 10.0005 12.8588C9.81728 12.8588 9.64143 12.7867 9.511 12.658L5.603 8.828C5.53875 8.76473 5.48773 8.68931 5.4529 8.60613C5.41807 8.52295 5.40014 8.43368 5.40014 8.3435C5.40014 8.25333 5.41807 8.16405 5.4529 8.08087C5.48773 7.99769 5.53875 7.92227 5.603 7.859C5.73313 7.73015 5.90886 7.65787 6.092 7.65787C6.27513 7.65787 6.45086 7.73015 6.581 7.859L10 11Z'
											fill='#ffffff'
										/>
									</svg>
								)}
							</div>
						) : (
							<div style={{ height: '24px' }}></div>
						)}
						<div
							className='page-section-title-scroll'
							ref={tabRefs.current[4]}></div>
						<div className={'course-page-section-title'}>
							Know Your Instructors
							<div
								style={{
									height: '1px',
									background: 'linear-gradient(to right, #a4a4a4, transparent)',
									width: '200px',
									marginLeft: '8px',
								}}></div>
						</div>
						{calendarItem && calendarItem?.instructorData ? (
							calendarItem?.instructorData?.map(
								(instructor: any, index: number) => {
									return (
										<>
											<KnowYourInstructor dark instructorData={instructor} />
											{index !== calendarItem?.instructorData?.length - 1 && (
												<div
													style={{
														height: '1px',
														borderBottom: '1px dashed rgb(214, 214, 214)',
														width: '344px',
														marginBottom: '12px',
													}}></div>
											)}
										</>
									);
								}
							)
						) : (
							<></>
						)}
						<span
							className='page-section-title-scroll'
							ref={tabRefs.current[5]}></span>
						<div className={'course-page-section-title'}>
							FAQs
							<div
								style={{
									height: '1px',
									background: 'linear-gradient(to right, #a4a4a4, transparent)',
									width: '200px',
									marginLeft: '8px',
								}}></div>
						</div>
						<div style={{ width: '344px' }}>
							{calendarItem?.FAQs?.map((faq: any, index: any) => (
								<FAQ
									dark
									selectedIndex={selectedFAQIndex}
									index={index}
									item={faq}
									onClickHandler={onFAQClickHandler}
								/>
							))}
						</div>
						<div onClick={() => chatWhatsAppsHandler(false)}>
							<img
								ref={whatsappRef}
								className='course-whatsapp-bottom'
								src={whatsappBar}
								alt=''
							/>
						</div>
						<Support
							ref={supportRef}
							onClick={() => chatWhatsAppsHandler(true)}
							style={{
								position: 'fixed',
								alignSelf: 'center',
								bottom: '84px',
								right: '2px',
								zIndex: '10000000001',
								cursor: 'pointer',
								backgroundColor: 'transparent',
							}}
							width={64}
							height={64}
						/>
					</div>

					<div
						style={{ backgroundColor: '#232323' }}
						className='plan-fixed-bottom-container'>
						{recordingStatus && (
							<div className='plan-fixed-bottom-recordings'>
								<div className='plan-fixed-bottom-recordings-text'>
									<p className='plan-fixed-bottom-recordings-text-duration'>
										Recordings for 6 weeks
									</p>
									<p className='plan-fixed-bottom-recordings-text-price'>
										INR {calendarItem?.recording_offer_price}{' '}
										<span>INR {calendarItem?.recording_actual_price} </span>
									</p>
									<p
										onClick={() => {
											setShowRecordingsModal(true);
											Mixpanel.track('click_view_benefits', {
												event_action: 'clicked',
												event_page: events_page.course_landing_page,
												course_name: calendarItem?.title,
												phone: userData?.phone || userData?.number,
											});
										}}
										className='plan-fixed-bottom-recordings-text-benefits'>
										View Benefits
									</p>
								</div>
								<div className='plan-fixed-bottom-recordings-button'>
									<SecondaryButton
										onClick={() => {
											setRecordingStatus(false);
											Mixpanel.track('click_remove_recording', {
												event_action: 'clicked',
												event_page: events_page.course_landing_page,
												course_name: calendarItem?.title,
												phone: userData?.phone || userData?.number,
											});
										}}
										style={{ padding: '4px 8px' }}>
										Remove
									</SecondaryButton>
								</div>
							</div>
						)}
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<span
								style={{ color: '#ffffff' }}
								className='plan-fixed-bottom-price'>{`₹ ${
								calendarItem?.batch?.[0]?.offers_valid
									? recordingStatus
										? Number(calendarItem?.batch?.[0]?.course_offer_price) +
										  Number(calendarItem?.recording_offer_price)
										: Number(calendarItem?.batch?.[0]?.course_offer_price)
									: recordingStatus
									? Number(calendarItem?.batch?.[0]?.price) +
									  Number(calendarItem?.recording_offer_price)
									: Number(calendarItem?.batch?.[0]?.price)
							}`}</span>

							{calendarItem?.batch?.[0]?.offers_valid && (
								<span
									className='plan-fixed-bottom-price'
									style={{
										color: '#A4A4A4',
										fontSize: '14px',
										marginLeft: '2px',
										textDecoration: 'line-through',
									}}>{` ₹${
									recordingStatus
										? Number(calendarItem?.batch?.[0]?.price) +
										  Number(calendarItem?.recording_offer_price)
										: Number(calendarItem?.batch?.[0]?.price)
								}`}</span>
							)}
						</div>
						<button
							disabled={
								calendarItem?.batch?.[0]?.user_already_enrolled ||
								currentDateMoment.isAfter(formattedDate)
							}
							className='plan-fixed-bottom-button'
							style={{
								background:
									calendarItem?.batch?.[0]?.user_already_enrolled && '#A4A4A4',
							}}
							onClick={proceedFromCourseDescriptionPage}>
							{currentDateMoment.isAfter(formattedDate)
								? 'Enrollment closed'
								: calendarItem?.batch?.[0]?.user_already_enrolled
								? 'Already enrolled'
								: 'Enrol Now'}
						</button>
					</div>
				</div>
			</div>
			{calendarItem?.lead_magnet_title &&
				calendarItem?.lead_magnet_title.length && (
					<CourseGiftModal
						showCourseGiftModal={giftModal}
						title={calendarItem.lead_magnet_title}
						onHideCourseGiftModal={handleHideGiftModal}
						handleDownloadClick={handleGiftDownloadClick}
					/>
				)}
			<CourseDetailsModal
				showCourseDetailsModal={detailsModal}
				onHideCourseDetailsModal={handleHideDetailsModal}
				handleDownloadClick={handleDetailsDownloadClick}
				name={name}
				setName={setName}
				courseName={calendarItem?.title}
				phone={detailsPhone}
				setPhone={setDetailsPhone}
			/>
			<BottomSheet
				customStyle={{ height: 'max-content', backgroundColor: '#1b1625' }}
				show={showRecordingsModal}
				showHeader={false}
				title=''
				hideOnOverlayClick={false}
				onHide={() => setShowRecordingsModal(false)}
				content={
					<div className='recordings-modal'>
						<div
							onClick={() => setShowRecordingsModal(false)}
							style={{
								width: '100%',
								alignItems: 'end',
								display: 'flex',
								justifyContent: 'end',
							}}>
							<img
								style={{ alignSelf: 'end' }}
								src={CloseRecordingModelIcon}
								alt=''
							/>
						</div>

						<div
							style={{
								width: '100%',
								alignItems: 'center',
								display: 'flex',
								justifyContent: 'center',
								marginTop: '24px',
								marginBottom: '32px',
							}}>
							<img style={{ width: '90%' }} src={RecordingBanner} alt='' />
						</div>

						<div className='recordings-modal-text'>
							<p className='recordings-modal-title'>
								Extended access to class recordings post-course at 25% off
								<span>
									₹{calendarItem?.recording_offer_price}{' '}
									<span>(₹{calendarItem?.recording_actual_price})</span> for 6
									weeks!
								</span>
							</p>
						</div>
						<div className='recordings-modal-points'>
							<div className='recordings-modal-points-item'>
								<img src={RecordingsIcon1} alt='' />
								<p>
									Gets activated after course completion for continuing your
									journey
								</p>
							</div>
							<div className='recordings-modal-points-item'>
								<img src={RecordingsIcon2} alt='' />
								<p>
									⁠Enjoy class recordings anytime , anywhere , at your
									convenience
								</p>
							</div>
						</div>
					</div>
				}
			/>
		</>
	);
};

export default UpcomingCourseDescriptionPage;
