import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Faqs, planTestimonials, userProfileData } from '../../../utils/utils';
import { Mixpanel } from '../../../analytics/Mixpanel';
import FAQ from './FAQ';
import PlanVariant from './PlanVariant';
import PausePlanModel from '../../../components-v2/models/PausePlanModel';
import impactImage from '../../../assets/webp-images/plan-impact-lives.png';
import planVideo from '../../../assets/videos/plan-testimonial.mp4';
import Shimmer from '../../../components-v2/shimmer/Shimmer';
import planPointerIcon1 from '../../../assets/new-icons/plan-pointer-icon-1.svg';
import planPointerIcon2 from '../../../assets/new-icons/plan-pointer-icon-2.svg';
import planPointerIcon3 from '../../../assets/new-icons/plan-pointer-icon-3.svg';
import volumeIcon from '../../../assets/new-icons/volume-icon.png';
import volumeMutedIcon from '../../../assets/new-icons/volume-muted-icon.png';
import NewTestimonialCard from '../../../components-v2/new-testimonial-card/NewTestimonialCard';
import planCta from '../../../assets/webp-images/plan-cta.png';
import planCtaExp from '../../../assets/webp-images/plan-cta-exp.png';

import beginnerPro from '../../../assets/webp-images/beginner-pro.png';

import UserProfileCard from './UserProfileCard';
import './PlanDescriptionPage.css';
import { useSelector } from 'react-redux';

interface TriggeredEvents {
	[key: string]: boolean;
}

const sectionRanges = {
	section1: {
		start: 0,
		end: 500,
		section_heading: 'Choose your perfect plan',
	},
	section2: {
		start: 100,
		end: 800,
		section_heading: 'Real stories, real impact',
	},
	section3: {
		start: 650,
		end: 800,
		section_heading: 'Designed for people like you',
	},
	section4: {
		start: 750,
		end: 900,
		section_heading: 'Impact we created',
	},
	section5: { start: 900, end: 1100, section_heading: 'FAQ and Support' },
} as { [key: string]: { start: number; end: number; section_heading: string } };

interface PlanDescriptionPageProps {
	plans: any;
	isCommunityMember: boolean;
	selectedPlanIndex: number;
	isUserLogin: boolean;
	utm_campaign: string;
	setSelectedPlanVariant: any;
	onProceedFromPlanDetailsPageHandler: () => void;
}

const PlanDescriptionPage: React.FC<PlanDescriptionPageProps> = ({
	selectedPlanIndex,
	onProceedFromPlanDetailsPageHandler,
	plans,
	utm_campaign,
	isCommunityMember,
	setSelectedPlanVariant,
}) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [selectedFAQIndex, setSelectedFAQIndex] = useState<number | null>();
	const [showMore, setShowMore] = useState(false);
	const [showPauseBanner, setShowPauseBanner] = useState(false);
	const [selectedPlanVariantIndex, setSelectedPlanVariantIndex] =
		useState<number>(selectedPlanIndex || 1);
	const [animateUserProfile, setAnimateUserProfile] = useState(false);
	const [muted, setMuted] = useState(true);
	const [isPayNowClicked, setIsPayNowClicked] = useState(false);
	const subscription_experiment_key = useSelector(
		(state: any) => state.abExperiment.data.subscription_experiment_key
	);
	const videoRef = useRef<HTMLVideoElement>(null);
	const userProfileRef = useRef<any>(null);

	const [triggeredEvents, setTriggeredEvents] = useState<TriggeredEvents>({
		section1: false,
		section2: false,
		section3: false,
		section4: false,
		section5: false,
	});

	const markMixPanelEvent = (position: number) => {
		const checkAndTriggerEvent = (
			sectionKey: string,
			start: number,
			end: number
		) => {
			if (
				!triggeredEvents[sectionKey] &&
				position >= start - 10 &&
				position <= end + 10
			) {
				Mixpanel.track('view_payment_landing_page_section', {
					event_action: 'viewed',
					event_section: sectionKey,
					section_heading: sectionRanges[sectionKey].section_heading,
					event_page: 'plan_landing_page',
					plan_name:
						subscription_experiment_key === 'subscription_A'
							? `${plans?.[0]?.app_display_name}, ${plans?.[1]?.app_display_name}`
							: `${plans?.[0]?.display_name || plans?.[0]?.name}, ${
									plans?.[1]?.display_name || plans?.[1]?.name
							  }`,
				});
				setTriggeredEvents((prevEvents: any) => ({
					...prevEvents,
					[sectionKey]: true,
				}));
			}
		};

		Object.entries(sectionRanges).forEach(([sectionKey, { start, end }]) => {
			checkAndTriggerEvent(sectionKey, start, end);
		});
	};

	useEffect(() => {
		if (!triggeredEvents['section1'] && plans) {
			Mixpanel.track('view_payment_landing_page_section', {
				event_action: 'viewed',
				event_section: 'section1',
				section_heading: 'Choose your perfect plan!',
				event_page: 'plan_landing_page',
				plan_name:
					subscription_experiment_key === 'subscription_A'
						? `${plans?.[0]?.app_display_name}, ${plans?.[1]?.app_display_name}`
						: `${plans?.[0]?.display_name || plans?.[0]?.name}, ${
								plans?.[1]?.display_name || plans?.[1]?.name
						  }`,
			});
			setTriggeredEvents((prevEvents: any) => ({
				...prevEvents,
				section1: true,
			}));
		}
		const handleScroll = () => {
			markMixPanelEvent(window.scrollY);
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [triggeredEvents, plans]);

	const onClickShowMoreReviews = () => {
		setShowMore((prevState) => !prevState);
		Mixpanel.track('click_show_more_reviews', {
			event_action: 'clicked',
			event_page: 'plan_landing_page',
		});
	};

	const onFAQClickHandler = (index: number) => {
		try {
			if (selectedFAQIndex !== index) {
				Mixpanel.track('click_view_FAQs_on_plan_landing_page', {
					event_action: 'clicked',
					faq_heading: Faqs[index],
					event_page: 'plan_landing_page',
				});
			}
		} catch (e) {}
		setSelectedFAQIndex((prevState) => (prevState === index ? null : index));
	};

	const onProceed = () => {
		Mixpanel.track('click_proceed_to_pay', {
			event_action: 'clicked',
			plan_name:
				subscription_experiment_key === 'subscription_A'
					? plans[selectedPlanVariantIndex]?.app_display_name
					: plans[selectedPlanVariantIndex]?.name,
			plan_amount: plans[selectedPlanVariantIndex]?.total_price,
			event_page: 'plan_landing_page',
		});

		setIsPayNowClicked(true);
		onProceedFromPlanDetailsPageHandler();
		setTimeout(() => {
			setIsPayNowClicked(false);
		}, 5000);
	};

	useEffect(() => {
		setSelectedPlanVariant(plans[selectedPlanVariantIndex]);
	}, [plans, selectedPlanVariantIndex, setSelectedPlanVariant]);

	useEffect(() => {
		window.addEventListener('scroll', () => {
			if (
				videoRef.current &&
				videoRef.current?.getBoundingClientRect().top < window.innerHeight
			) {
				videoRef.current.play();
			}
			if (
				userProfileRef.current &&
				userProfileRef.current.getBoundingClientRect().top < window.innerHeight
			) {
				setAnimateUserProfile(true);
			}
		});
	}, []);

	const handlePlanVariantClick = (
		selectedIndex: number,
		duration: number,
		price: number
	) => {
		setSelectedPlanVariantIndex(selectedIndex);
		setSelectedPlanVariant(plans[selectedIndex]);
		try {
			Mixpanel.track('click_to_choose_plan_duration', {
				event_action: 'clicked',
				duration: duration,
				plan_price: price,
			});
		} catch (err) {}
	};

	return (
		<Container className={'plan-description-container'}>
			<div
				className={'plan-description-component-wrapper'}
				style={{
					width: '100%',
				}}>
				{plans &&
					plans.map((plan: any, index: number) => (
						<>
							<img src='' alt='' />
							<PlanVariant
								utm_campaign={utm_campaign}
								count={plans?.length}
								free_extension={plan?.free_extension}
								offer={plan?.offer}
								extra_offer={plan?.extra_offer}
								strike_price={plan?.strike_price}
								pause_limit={plan?.pause_limit}
								key={index}
								app_display_name={plan?.app_display_name}
								onClick={(selectedIndex) =>
									handlePlanVariantClick(
										selectedIndex,
										plan?.duration,
										plan?.total_price
									)
								}
								setShowPauseBanner={setShowPauseBanner}
								index={index}
								name={plan?.display_name || plan?.name}
								selectedIndex={selectedPlanVariantIndex || 0}
								type={plan?.type}
								duration={plan?.duration}
								monthly_price={
									isCommunityMember
										? plan?.community_monthly_price
										: plan?.monthly_price
								}
								total_price={
									isCommunityMember
										? plan?.community_total_price
										: plan?.total_price
								}
								recommended={plan.recommended}
							/>
						</>
					))}
			</div>

			{/* <div style={{ width: '100%', maxWidth: '375px', position: 'relative' }}>
				<div className='plan-description-track'></div>
				<div className='plan-description-pointer'>
					<img src={planPointerIcon1} alt='' />
					<div>
						<p>Calm mind + Deep 8hr sleep</p>
						<p>↳ within 2 months</p>
					</div>
				</div>
				<div className='plan-description-pointer'>
					<img src={planPointerIcon2} alt='' />
					<div>
						<p>Anxiety + Anger control</p>
						<p>↳ within 4 months</p>
					</div>
				</div>
				<div className='plan-description-pointer'>
					<img src={planPointerIcon3} alt='' />
					<div>
						<p>Lasting Wellness Habits</p>
						<p>↳ within 6 months, you'll see lasting changes & real results</p>
					</div>
				</div>
			</div> */}

			<div
				style={{
					maxWidth: '375px',
					width: '100%',
					marginTop: '36px',
				}}>
				<p
					style={{
						color: '#1B1625',
						fontFamily: 'Gordita-Medium',
						fontSize: '24px',
						width: '100%',
						alignSelf: 'start',
						textAlign: 'start',
						fontStyle: 'normal',
						fontWeight: 500,
						lineHeight: '28px',
					}}>
					Real stories, real impact
				</p>
				{planTestimonials
					.slice(0, showMore ? planTestimonials.length - 1 : 3)
					.map((testimonial, index) => {
						return <NewTestimonialCard key={index} data={testimonial} />;
					})}
			</div>
			<div
				className={'horizontal-container'}
				style={{
					width: '100%',
					maxWidth: '375px',
					alignItems: 'center',
					justifyContent: 'end',
					marginTop: '12px',
				}}
				onClick={onClickShowMoreReviews}>
				<span
					style={{
						color: 'var(--purple-gradient, #000)',
						fontFamily: 'Gordita-Medium',
						fontSize: '12px',
						fontStyle: 'normal',
						fontWeight: 500,
						alignSelf: 'center',
						textDecorationLine: 'underline',
						lineHeight: '16px',
						pointerEvents: 'none',
					}}>
					{showMore ? 'Show less' : 'Show more'}
				</span>
				{showMore ? (
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='16'
						height='17'
						viewBox='0 0 16 17'
						fill='none'>
						<path
							d='M12.5 10.7502L8 6.25024L3.5 10.7502'
							stroke='#1B1625'
							stroke-width='1.5'
							stroke-linecap='round'
							stroke-linejoin='round'
						/>
					</svg>
				) : (
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='16'
						height='17'
						viewBox='0 0 16 17'
						fill='none'>
						<path
							d='M12.5 6.25018L8 10.7502L3.5 6.25018'
							stroke='#1B1625'
							stroke-width='1.5'
							stroke-linecap='round'
							stroke-linejoin='round'
						/>
					</svg>
				)}
			</div>

			<div
				onClick={() => {
					Mixpanel.track('click_mute_unmute_testimonial_video', {
						event_action: 'clicked',
						next_state: muted ? 'unmute' : 'mute',
						event_page: 'plan_landing_page',
					});
					setMuted(!muted);
				}}
				style={{
					maxWidth: '375px',
					width: '100%',
					marginTop: '36px',
					position: 'relative',
				}}>
				<video
					ref={videoRef}
					style={{ width: '100%', borderRadius: '12px' }}
					playsInline
					loop
					muted={muted}
					src={planVideo}></video>
				<div
					style={{
						padding: '4px 8px',
						position: 'absolute',
						top: '8px',
						right: '8px',
						borderRadius: '1000px',
						backgroundColor: '#ffffff55',
					}}>
					<img
						style={{
							marginTop: '-4px',
							width: '16px',
							filter:
								'invert(100%) sepia(0%) saturate(13%) hue-rotate(225deg) brightness(100%) contrast(103%)',
						}}
						src={muted ? volumeMutedIcon : volumeIcon}
						alt=''
					/>
				</div>
			</div>

			<div
				style={{
					maxWidth: '375px',
					width: '100%',
					marginTop: '36px',
				}}>
				<p
					style={{
						color: '#1B1625',
						fontFamily: 'Gordita-Medium',
						fontSize: '24px',
						width: '100%',
						alignSelf: 'start',
						textAlign: 'start',
						fontStyle: 'normal',
						fontWeight: 500,
						lineHeight: '28px',
						marginBottom: 0,
					}}>
					Designed for people like you
				</p>
				<div
					ref={userProfileRef}
					style={{ position: 'relative', marginTop: '24px' }}>
					<div
						style={{
							position: 'absolute',
							left: 0,
							top: 0,
							width: '4px',
							borderRadius: '50px',
							height: '100%',
							backgroundColor: '#f4f4f4',
						}}></div>
					{userProfileData.map((userProfile, index) => {
						return (
							<UserProfileCard
								animate={animateUserProfile}
								key={index}
								data={userProfile}
								index={index}
							/>
						);
					})}
				</div>
			</div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					marginLeft: 'auto',
					marginRight: 'auto',
					marginTop: '16px',
					maxWidth: '375px',
					width: '100%',
				}}>
				<p
					style={{
						color: '#1B1625',
						fontFamily: 'Gordita-Medium',
						fontSize: '24px',
						width: '100%',
						alignSelf: 'start',
						textAlign: 'start',
						fontStyle: 'normal',
						fontWeight: 500,
						lineHeight: '28px',
						marginBottom: '16px',
					}}>
					{subscription_experiment_key === 'subscription_A'
						? 'Try once, Cancel anytime'
						: 'Your journey to ultimate peace'}
				</p>
			</div>
			<img
				style={{ maxWidth: '375px', width: '100%' }}
				src={beginnerPro}
				alt=''
			/>
			<div className='plan-description-people-stats'>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
						marginLeft: 'auto',
						marginRight: 'auto',
						marginTop: '16px',
						maxWidth: '375px',
						width: '100%',
					}}>
					<p
						style={{
							color: '#1B1625',
							fontFamily: 'Gordita-Medium',
							fontSize: '24px',
							width: '100%',
							alignSelf: 'start',
							textAlign: 'start',
							fontStyle: 'normal',
							fontWeight: 500,
							lineHeight: '28px',
							marginBottom: '16px',
						}}>
						Impact we created
					</p>
				</div>
				<img
					className='plan-description-people-stats-img'
					src={impactImage}
					alt=''
				/>
				<div className='plan-description-people-stats-row'>
					<div className='plan-description-people-stats-card'>
						<p style={{ color: '#4462EF' }} className='percentage'>
							42%
						</p>
						<p className='percentage-text'>experienced</p>
						<p className='text'>uninterrupted</p>
						<p className='subtext'>Deep sleep</p>
					</div>
					<div className='plan-description-people-stats-card'>
						<p style={{ color: '#B8ACF6' }} className='percentage'>
							26%
						</p>
						<p className='percentage-text'>experienced</p>
						<p className='text'>decrease in</p>
						<p className='subtext'>Anxiety level</p>
					</div>
					<div className='plan-description-people-stats-card'>
						<p style={{ color: '#FAA53D' }} className='percentage'>
							18%
						</p>
						<p className='percentage-text'>experienced</p>
						<p className='text'>improved</p>
						<p className='subtext'>Chronic pain</p>
					</div>
				</div>
			</div>

			<p
				className={'plan-description-faq-title'}
				style={{
					alignSelf: 'center',
					maxWidth: '375px',
					width: '100%',
					margin: '24px auto 0px auto',
				}}>
				FAQs
			</p>
			{Faqs.filter((faq) => {
				if (subscription_experiment_key === 'subscription_A') {
					return !faq.q.includes('refund');
				} else {
					return !faq.q.includes('subscription');
				}
			})?.map((faq, index) => (
				<FAQ
					key={index}
					selectedIndex={selectedFAQIndex}
					index={index}
					item={faq}
					onClickHandler={onFAQClickHandler}
				/>
			))}

			<div style={{ position: 'relative' }}>
				<div
					onClick={() => {
						handlePlanVariantClick(1, 180, 1499);
						onProceed();
					}}
					style={{
						position: 'absolute',
						top: '35%',
						left: '50%',
						transform: 'translateX(-50%)',
						width: '55%',
						height: '10%',
					}}></div>
				<img
					style={{ maxWidth: '375px', width: '100%', marginTop: '36px' }}
					src={
						subscription_experiment_key === 'subscription_A'
							? planCtaExp
							: planCta
					}
					alt=''
				/>
			</div>

			<div style={{ marginBottom: '132px' }}></div>
			<div className='plan-fixed-bottom-container'>
				{plans &&
					plans.length &&
					subscription_experiment_key !== 'subscription_A' && (
						<>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<span className='plan-fixed-bottom-price'>
									{'₹' + plans?.[selectedPlanVariantIndex]?.total_price}
								</span>
								<span className='plan-fixed-bottom-duration'>
									{plans?.[selectedPlanVariantIndex]?.display_name}
								</span>
							</div>
							<button
								disabled={isPayNowClicked}
								className='plan-fixed-bottom-button'
								onClick={onProceed}>
								{isPayNowClicked ? 'Please wait...' : 'Proceed to Pay'}
							</button>
						</>
					)}
				{subscription_experiment_key === 'subscription_A' && (
					<>
						<p
							style={{
								fontFamily: 'Gordita-Medium',
								fontSize: '12px',
								color: '#a4a4a4',
								textAlign: 'center',
								width: '100%',
								marginBottom: '8px',
							}}>
							Cancel anytime
						</p>
						<button
							disabled={isPayNowClicked}
							style={{ width: '100%' }}
							className='plan-fixed-bottom-button'
							onClick={onProceed}>
							{isPayNowClicked ? 'Please wait...' : 'Proceed to Pay'}
						</button>
					</>
				)}
			</div>
			<PausePlanModel
				setShowPauseBanner={setShowPauseBanner}
				showPauseBanner={showPauseBanner}
			/>
		</Container>
	);
};

export default PlanDescriptionPage;
