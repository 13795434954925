import { useMemo } from 'react';
import BackButton from '../../../components-v2/buttons/BackButton';
import bannerBg from '../../../assets/webp-images/plan-banner-bg.png';
import BestOutOfItIconWithName from '../../../components-v2/rows/BestOutOfItIconWithName';

import './Banner.css';

type Props = {
	onBackArrowClick: () => void;
};

const Banner: React.FC<Props> = ({ onBackArrowClick }) => {
	const bestOutOfIt = useMemo(
		() => [
			'30-min recorded classes at 10 time slots',
			'New meditation themes everyday',
			'Live sessions on alternate days',
		],
		[]
	);

	return (
		<div
			style={{
				width: '100%',
				maxWidth: '420px',
				padding: '0 22.5px',
				background: `url(${bannerBg})`,
				backgroundPosition: 'bottom',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				marginTop: '-16px',
				paddingTop: '16px',
				border: 'none',
				outline: 'none',
			}}>
			<div style={{ width: '100%', maxWidth: '375px', marginTop: '24px' }}>
				<BackButton isDark onClick={onBackArrowClick} />
			</div>
			<div
				style={{
					width: '100%',
					maxWidth: '375px',
					marginTop: '16px',
					paddingBottom: '48px',
				}}>
				<p
					style={{
						fontFamily: 'Gordita-Bold',
						color: '#ffffff',
						fontSize: '28px',
						marginBottom: '0',
					}}>
					Choose your perfect plan
				</p>
				{bestOutOfIt
					?.filter((value) => value !== null)
					.map((value, index) => (
						<BestOutOfItIconWithName
							textColor='#ffffff'
							key={index}
							index={index}
							name={value}
						/>
					))}
			</div>
		</div>
	);
};

export default Banner;
