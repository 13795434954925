import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	data: {
		plan_experiment_key: 'plan-B',
		onboarding_experiment_key: null,
		pricing_experiment_key: 'pricing-B',
		subscription_experiment_key: null,
	},
};

export const ABExperimentSlice = createSlice({
	name: 'ABExperiment',
	initialState,
	reducers: {
		addExperimentPlanGroupKey: (state, action) => {
			state.data.plan_experiment_key = action.payload;
		},
		addExperimentOnboardingGroupKey: (state, action) => {
			state.data.onboarding_experiment_key = action.payload;
		},
		addExperimentPricingGroupKey: (state, action) => {
			state.data.pricing_experiment_key = action.payload;
		},
		addExperimentSubscriptionGroupKey: (state, action) => {
			state.data.subscription_experiment_key = action.payload;
		},
	},
});

export const {
	addExperimentPlanGroupKey,
	addExperimentOnboardingGroupKey,
	addExperimentPricingGroupKey,
	addExperimentSubscriptionGroupKey,
} = ABExperimentSlice.actions;

export default ABExperimentSlice.reducer;
