import React, { memo, useEffect, useState } from 'react';
import './PlanVariant.css';
import { ReactComponent as RecommendedTag } from '../../../assets/recommended.svg';
import { COLORS } from '../../../utils/colors';
import CustomCard from '../../../components/card/CustomCard';
import { UTM_FOR_3_6_MONTHS } from '../../../utils/utils';
import { useSelector } from 'react-redux';
import planUsersChoice from './../../../assets/webp-images/plan-users-choice.png';
import { Mixpanel } from '../../../analytics/Mixpanel';

interface CircularCheckboxProps {
	title: string;
	subtitle: string;
	index: number;
	isSubscription?: boolean;
	selectedIndex: number;
}

const CircularRadioButton: React.FC<CircularCheckboxProps> = ({
	title,
	subtitle,
	index,
	selectedIndex,
	isSubscription,
}) => {
	const [isChecked, setIsChecked] = useState(false);

	useEffect(() => {
		setIsChecked(index === selectedIndex);
	}, [index, selectedIndex]);

	return (
		<div className='price-container'>
			<div className='price-left-container'>
				<div
					className={`${
						isChecked ? 'price-radio-selected' : 'price-radio'
					}`}></div>
				<span className='price-title'>
					{isSubscription ? (
						<span style={{ color: '#000000', fontSize: '20px' }}>{title}</span>
					) : (
						<>
							{title}
							<span>{title === '01' ? ' Month' : ' Months'}</span>
						</>
					)}
				</span>
			</div>
			<span
				className={`${
					isChecked ? 'price-subtitle-selected' : 'price-subtitle'
				}`}>{`₹ ${subtitle}`}</span>
		</div>
	);
};

interface PlanVariantProps {
	strike_price: number;
	extra_offer: number;
	free_extension: string;
	offer: string;
	utm_campaign?: string;
	name: string;
	monthly_price: number;
	total_price: number;
	duration: number;
	recommended: boolean;
	type: string;
	index: number;
	selectedIndex: number;
	onClick: (index: number) => void;
	pause_limit: number;
	app_display_name?: string;
	setShowPauseBanner: any;
	count: number;
}

const PlanVariant: React.FC<PlanVariantProps> = ({
	count,
	setShowPauseBanner,
	pause_limit,
	strike_price,
	extra_offer,
	free_extension,
	offer,
	utm_campaign,
	name,
	monthly_price,
	total_price,
	app_display_name,
	duration,
	recommended,
	type,
	index,
	selectedIndex,
	onClick,
}) => {
	const [longerPlanCheckDuration, setLongerPlanCheckDuration] = useState(90);
	const subscription_experiment_key = useSelector(
		(state: any) => state.abExperiment.data.subscription_experiment_key
	);

	const user = useSelector((state: any) => state?.auth?.user?.data);

	useEffect(() => {
		if (subscription_experiment_key === 'subscription_A') {
			setLongerPlanCheckDuration(30);
		}
	}, [user, subscription_experiment_key]);

	return (
		<>
			{duration > longerPlanCheckDuration && (
				<div style={{ width: '100%', maxWidth: '375px' }}>
					<img
						src={planUsersChoice}
						style={{
							width: '100px',
							marginTop: '16px',
							marginRight: 'auto',
						}}
						alt=''
					/>
				</div>
			)}
			<CustomCard
				onClick={onClick.bind(null, index)}
				style={{
					height: 'auto',
					marginTop: 0,
					width: '100%',
					maxWidth: '375px',
					borderRadius: '12px',
					paddingBottom: duration > longerPlanCheckDuration ? '36px' : '0',
					backgroundColor: COLORS.WHITE,
					position: 'relative',
					border:
						index === selectedIndex
							? '1px solid var(--Primary-Gradient, #4462EF)'
							: '1px solid var(--Light-Grey-2, #E4E4E4)',
				}}>
				{offer && duration > longerPlanCheckDuration && (
					<div className='plan-variants-badge'>{offer}</div>
				)}

				<div className={`plan-variants-row`}>
					<CircularRadioButton
						index={index}
						isSubscription={subscription_experiment_key === 'subscription_A'}
						selectedIndex={selectedIndex}
						title={`${
							subscription_experiment_key === 'subscription_A'
								? app_display_name
								: name?.split(' ')?.[0]
						}`}
						subtitle={`${total_price}`}
					/>
					{recommended && (
						<RecommendedTag
							style={{ position: 'absolute', right: 10, top: -12 }}
							width={103}
							height={24}
						/>
					)}
				</div>
				{duration > longerPlanCheckDuration && (
					<div className='plan-variants-monthly-price'>
						<p>
							🥳 Starting at ₹{Math.round(total_price / (duration / 30))}
							/month
						</p>
					</div>
				)}
			</CustomCard>
		</>
	);
};

export default memo(PlanVariant);
