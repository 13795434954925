import React, { ChangeEvent, useEffect, useState } from 'react';

import {
	enrolUserToLiveClasses,
	getLiveStreamingPlans,
	getUpcomingCourses,
	getUserByUserId,
} from '../../services/Firebase';
import { Mixpanel } from '../../analytics/Mixpanel';
import { useLocation, useNavigate } from 'react-router-dom';
import CheckoutPage from './components/CheckoutPage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Plan.css';
import '../login/Login.css';
import useAuthenticatedUsers from '../../hooks/useAuthenticatedUsers';
import PlanDescriptionPage from './components/PlanDescriptionPage';
import {
	DAILY_CLASS_PAYMENT_TYPES,
	generateUniqueId,
	getRazorpayOptions,
	isEmpty,
	loadRazorpayScript,
	UTM_FOR_3_6_MONTHS,
} from '../../utils/utils';
import { events_action } from '../../analytics/Events';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components-v2/header/Header';
import seek_logo_url from '../../assets/seek-logo.svg';
import BackButton from '../../components-v2/buttons/BackButton';
import Shimmer from '../../components-v2/shimmer/Shimmer';
import LoginModal from '../../components-v2/models/LoginModel';
import LoginFailureModal from '../../components-v2/models/LoginFailureModel';
import PaymentSuccessModal from '../../components-v2/models/PaymentSuccessModel';
import PaymentFailedModal from '../../components-v2/models/PaymentFailedModel';
import useLoginHook from '../../hooks/useLogin';
import useRazorpay from '../../hooks/useRazorpay';
import useFetchStreamingPlan from '../../hooks/useFetchStreamingPlan';
import { setUtmCampaign } from '../../store/features/UtmSlice';
import { addUsers, setReloadPlan } from '../../store/features/AuthSlice';
import { setUpcomingCourses } from '../../store/features/UpcomingClassesSlice';
import PlanAddonBottomsheet from './components/PlanAddonBottomsheet';
import Banner from './components/Banner';
import DisableBackButtonOnRoute from '../../components-v2/disable-back/DisableBackButtonOnRoute';
import moment from 'moment-timezone';

export enum STATES {
	LOADING,
	PLAN_DESCRIPTION_PAGE,
	CHECKOUT_PAGE,
}

function Plan() {
	const userData = useAuthenticatedUsers(true);
	// const prevScreenName = 'plan_landing_page';
	const user = useSelector((state: any) => state?.auth?.user?.data);

	const [state, setState] = useState<STATES>(STATES.PLAN_DESCRIPTION_PAGE);
	const [loader, setLoader] = useState(true);
	const [loginLoader, setLoginLoader] = useState(false);
	const [paymentSuccessPopup, setPaymentSuccessPopup] = useState(false);
	const [paymentFailedPopup, setPaymentFailedPopup] = useState(false);
	const [plans, setPlans] = useState<any>();
	const [addonOpen, setAddonOpen] = useState(false);
	const [selectedPlanIndex, setSelectedPlanIndex] = useState<any>(1);
	const [selectedPlanVariant, setSelectedPlanVariant] = useState<any>();
	const [showLoginPopup, setShowLoginPopup] = useState(false);
	const [showOtpPopup, setShowOtpPopup] = useState(false);
	const [phone, setPhone] = useState<string>('');
	const [error, setError] = useState<string>('');
	const [addonAdded, setAddonAdded] = useState(false);
	const [addonCourse, setAddonCourse] = useState<any>(null);
	const userPlan = useSelector((state: any) => state.plan?.userPlan);
	const utmParams = useSelector((state: any) => state.utmDetails);
	const subscription_experiment_key = useSelector(
		(state: any) => state.abExperiment.data.subscription_experiment_key
	);
	const location = useLocation();
	const prevScreenName = location?.state?.screenName;

	useFetchStreamingPlan(userData, user, false);
	const dispatch = useDispatch();

	const upcomingCourses = useSelector(
		(state: any) => state.upcomingClasses?.upcomingCourse?.data
	);

	const onClickLogin = (phone: string) => {
		Mixpanel.track('click_login_popup_checkout_page', {
			event_page: 'payment_screen/payment_one',
			event_action: events_action.clicked,
			// email: email,
			phone: phone,
		});
		setLoginLoader(true);
		onLoginSubmitHandler(phone).then(() => {
			setLoginLoader(false);
		});
	};

	const onLoginSuccess = (loginResult: any) => {
		toast.success('Login Successful', {
			position: toast.POSITION.TOP_CENTER,
			autoClose: 2000,
			hideProgressBar: true,
		});
		Mixpanel.track('login_successful_checkout_page', {
			event_page: 'payment_screen/payment_one',
			event_action: events_action.viewed,
			phone: phone,
		});
	};
	const onLoginFailure = (loginResult: any) => {
		Mixpanel.track('login_failed_checkout_page', {
			event_page: 'payment_screen/payment_one',
			event_action: events_action.viewed,
			phone: phone,
		});
	};

	const {
		isLoginSuccessful,
		isLoginFailure,
		setIsLoginSuccessful,
		setIsLoginFailure,
		onLoginSubmitHandler,
		onOtpVerifyHandler,
	} = useLoginHook({
		page: 'plan_landing_page',
		scope: 'PLAN',
		setLoginLoader,
		setError,
		setShowOtpPopup,
		setShowLoginPopup,
		onLoginSuccess,
		onLoginFailure,
	});

	useEffect(() => {}, [upcomingCourses]);

	const { displayRazorpay } = useRazorpay({
		setPaymentFailedPopup,
		setPaymentSuccessPopup,
		setShowLoginPopup,
		user,
		userData,
		selectedPlanVariant,
		course: addonAdded ? addonCourse : undefined,
		prevScreenName,
	});
	const navigate = useNavigate();

	const onHidePlanBottomsheet = () => {};

	useEffect(() => {
		(async () => {
			try {
				let currentUser = user;
				if (isEmpty(currentUser)) {
					const userLoginResult = await getUserByUserId(userData?.uid);
					dispatch(addUsers(userLoginResult?.userData));
					currentUser = userLoginResult?.userData;
				}
			} catch (e) {
				console.error('Error fetching plans:', e);
			}
		})();
	}, [utmParams, user, userData?.uid, dispatch]);

	useEffect(() => {
		if (!!subscription_experiment_key) {
			getLiveStreamingPlans(subscription_experiment_key).then(
				(plansResponse) => {
					const filteredArray = plansResponse.plans[0]?.variants?.sort(
						(a: any, b: any) => a?.duration - b?.duration
					);

					setPlans(filteredArray);
					setSelectedPlanVariant(filteredArray[0]);
					setLoader(false);
				}
			);
		}
	}, [subscription_experiment_key]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (plans && plans[0].total_price === 499) {
				Mixpanel.track('view_payment_landing_page', {
					event_action: 'viewed',
					event_page: 'plan_landing_page',
					plan_name: 'Monthly, Quarterly',
				});
			} else {
				Mixpanel.track('view_payment_landing_page', {
					event_action: 'viewed',
					event_page: 'plan_landing_page',
					plan_name: '3 Months, 6 Months',
				});
			}
		}, 1000);

		return () => {
			clearTimeout(timeout);
		};
	}, [plans]);

	useEffect(() => {
		Mixpanel.startRecording();

		return () => Mixpanel.stopRecording();
	}, []);

	useEffect(() => {
		if (!upcomingCourses.length) {
			getUpcomingCourses(user?.uid || userData?.uid)
				.then((coursesResult: any) => {
					dispatch(setUpcomingCourses(coursesResult.data));
					const course =
						coursesResult.data.find(
							(course: any) => course.courseId === 'DMq6piFDvmMrcWhzhhU2'
						) || null;
					if (course && !course.batch[0].user_already_enrolled) {
						setAddonCourse(course);
					}
				})
				.catch((err: any) => {
					console.log('getUpcomingClasses err', err);
				});
		} else {
			const course =
				upcomingCourses.find(
					(course: any) => course.courseId === 'DMq6piFDvmMrcWhzhhU2'
				) || null;
			if (course && !course.batch[0].user_already_enrolled) {
				setAddonCourse(course);
			}
		}
	}, []);

	const onProceedFromPlanDetailsPageHandler = async () => {
		if (!userData && isEmpty(user)) {
			setShowLoginPopup(true);
			Mixpanel.track('view_login_popup_checkout_page', {
				event_action: 'viewed',
				plan_amount: selectedPlanVariant?.total_price,
				plan_name:
					subscription_experiment_key === 'subscription_A'
						? selectedPlanVariant?.app_display_name
						: selectedPlanVariant?.name,
				startDate: moment().format('DD MMM YYYY'),
				event_page: prevScreenName
					? prevScreenName
					: 'payment_screen/payment_one',
			});
			return;
		}

		const res = await loadRazorpayScript(
			'https://checkout.razorpay.com/v1/checkout.js'
		);

		if (!res) {
			toast.error(`Razorpay SDK failed to load. Are you online?`, {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 2000,
				hideProgressBar: true,
			});
			return;
		}

		const paymentType =
			subscription_experiment_key === 'subscription_A'
				? DAILY_CLASS_PAYMENT_TYPES.SUBSCRIPTION
				: DAILY_CLASS_PAYMENT_TYPES.ONE_TIME;

		const planDocId = generateUniqueId(
			(user?.uid || userData?.uid)!,
			selectedPlanVariant.product_id
		);

		const response = await enrolUserToLiveClasses(
			user.uid || userData.uid,
			selectedPlanVariant,
			planDocId,
			paymentType
		);

		const options = getRazorpayOptions(
			subscription_experiment_key === 'subscription_A'
				? 'Seek daily subscription'
				: `Daily Live Class + Course Enrollment Fee-${planDocId}-undefined`,
			selectedPlanVariant?.total_price,
			'Seek daily subscription',
			user.phone || '9959637700',
			user.name || '',
			selectedPlanVariant?.currency,
			response?.order_id,
			paymentType,
			async function (response: any) {
				if (response.razorpay_payment_id) {
					Mixpanel.track('view_payment_successful', {
						event_action: 'viewed',
						plan_amount: selectedPlanVariant.total_price,
						plan_name:
							subscription_experiment_key === 'subscription_A'
								? selectedPlanVariant.app_display_name
								: selectedPlanVariant.name,
						phone:
							user?.phoneNumber ||
							user?.phone ||
							userData?.phoneNumber ||
							userData?.phone,
						userId: user?.uid || userData?.uid,
						event_page: 'payment_screen/payment_one',
					});
					navigate('/plan/payment-successful', {
						state: {
							selectedPlanVariant: selectedPlanVariant,
							course: null,
							prevScreenName: prevScreenName,
							currently_active_variant:
								userPlan?.activeStreamingPlan?.currently_active_variant,
						},
						replace: false,
					});
					dispatch(setReloadPlan(true));
				} else {
					setPaymentFailedPopup(true);
					Mixpanel.track('payment_failure', {
						event_action: 'viewed',
						plan_amount: selectedPlanVariant.total_price,
						plan_name:
							subscription_experiment_key === 'subscription_A'
								? selectedPlanVariant?.app_display_name
								: selectedPlanVariant?.name,
						phone:
							user?.phoneNumber ||
							user?.phone ||
							userData?.phoneNumber ||
							userData?.phone,
						event_page: 'payment_screen/payment_one',
					});
				}
			},
			{
				ondismiss: function (response: any) {
					setPaymentFailedPopup(true);
					Mixpanel.track('payment_failure', {
						event_action: 'viewed',
						plan_amount: selectedPlanVariant.total_price,
						plan_name:
							subscription_experiment_key === 'subscription_A'
								? selectedPlanVariant.app_display_name
								: selectedPlanVariant.name,
						phone:
							user?.phoneNumber ||
							user?.phone ||
							userData?.phoneNumber ||
							userData?.phone,
						event_page: 'payment_screen/payment_one',
					});
				},
			}
		);

		const paymentObject = (window as any).Razorpay(options);
		paymentObject.open();
	};

	const onBackArrowClick = () => {
		if (state === STATES.CHECKOUT_PAGE) {
			setState(STATES.PLAN_DESCRIPTION_PAGE);
			return;
		}
		setLoader(true);
		if (location?.state?.screenName === 'AccountCreated') {
			navigate('/sign-up-successful');
		} else {
			if (userData != null || !isEmpty(user) || isLoginSuccessful) {
				navigate('/live-classes');
			} else {
				navigate('/');
			}
		}
	};

	const handlePhoneChange = (
		value: string,
		country: string,
		e: ChangeEvent<HTMLInputElement>,
		formattedValue: string
	): void => {
		setError('');
		setPhone(value);
	};

	const closeErrorPopup = () => {
		setError('');
	};

	const closeModal = () => {
		setIsLoginSuccessful(false);
		setIsLoginFailure(false);
	};

	const onSignUpClickHandler = () => {
		setLoader(false);
		setShowLoginPopup(false);
		try {
			navigate('/onboarding');
		} catch (e) {}
	};

	useEffect(() => {
		Mixpanel.startRecording();

		return () => Mixpanel.stopRecording();
	}, []);

	const onKarmaPointsClickHandler = () => {
		Mixpanel.track('click_karma_points_badge', {
			event_action: 'clicked',
			context_user_id: user?.uid,
			total_karma_points: user?.total_karma || 0,
			event_page: 'home_screen',
		});
		navigate('/karma', {
			state: { email: user?.email, total_karma: user?.total_karma },
			replace: false,
		});
	};

	return (
		<>
			<DisableBackButtonOnRoute targetPath='/plan' />
			<div className={'plan-container'}>
				{state === STATES.PLAN_DESCRIPTION_PAGE && (
					<Header
						blue
						onClick={onKarmaPointsClickHandler}
						logoUrl={seek_logo_url}
						karma_points={user?.total_karma}
					/>
				)}
				{loader && (
					<>
						<Shimmer
							style={{
								height: '100px',
								width: '100%',
								maxWidth: '375px',
							}}
						/>
						<Shimmer
							style={{ height: '200px', width: '100%', maxWidth: '375px' }}
						/>
						<Shimmer
							style={{ height: '300px', width: '100%', maxWidth: '375px' }}
						/>
						<Shimmer
							style={{ height: '200px', width: '100%', maxWidth: '375px' }}
						/>
					</>
				)}
				{!loader && state === STATES.PLAN_DESCRIPTION_PAGE && plans && (
					<>
						<Banner onBackArrowClick={onBackArrowClick} />
						<PlanDescriptionPage
							utm_campaign={utmParams?.utm_campaign?.trim() || ''}
							isUserLogin={
								userData != null || !isEmpty(user) || isLoginSuccessful
							}
							selectedPlanIndex={selectedPlanIndex}
							isCommunityMember={user?.referralCode}
							plans={plans}
							setSelectedPlanVariant={setSelectedPlanVariant}
							onProceedFromPlanDetailsPageHandler={
								onProceedFromPlanDetailsPageHandler
							}
						/>
					</>
				)}
				{!loader && state === STATES.CHECKOUT_PAGE && (
					<CheckoutPage
						setAddonOpen={setAddonOpen}
						isUserLogin={
							userData != null || !isEmpty(user) || isLoginSuccessful
						}
						isCommunityMember={user?.referralCode}
						total_karma={user?.total_karma}
						selectedPlanVariant={selectedPlanVariant}
						course={addonCourse}
						payNowClickHandler={displayRazorpay}
						onBackArrowClick={onBackArrowClick}
						addonAdded={addonAdded}
						setAddonAdded={setAddonAdded}
					/>
				)}
				<PaymentSuccessModal
					paymentSuccessPopup={paymentSuccessPopup}
					setPaymentSuccessPopup={setPaymentSuccessPopup}
					selectedPlanVariant={selectedPlanVariant}
				/>
				<PaymentFailedModal
					paymentFailedPopup={paymentFailedPopup}
					setPaymentFailedPopup={setPaymentFailedPopup}
					selectedPlanVariant={selectedPlanVariant}
					addonAdded={addonAdded}
					displayRazorpay={displayRazorpay}
				/>
				<LoginFailureModal
					isLoginFailure={isLoginFailure}
					closeModal={closeModal}
					onSignUpClickHandler={onSignUpClickHandler}
				/>
				<LoginModal
					page={'plan_landing_page'}
					setError={setError}
					showEnrollNowCta={true}
					onEnrollNowClickHandler={onSignUpClickHandler}
					onOtpVerifyHandler={onOtpVerifyHandler}
					showOtpPopup={showOtpPopup}
					setShowOtpPopup={setShowOtpPopup}
					showLoginPopup={showLoginPopup}
					setShowLoginPopup={setShowLoginPopup}
					error={error}
					closeErrorPopup={closeErrorPopup}
					phone={phone}
					loading={loginLoader}
					handlePhoneChange={handlePhoneChange}
					onLoginSubmitHandler={onClickLogin}
				/>
				{!!addonCourse && (
					<PlanAddonBottomsheet
						course={addonCourse}
						state={state}
						addonAdded={addonAdded}
						setAddonAdded={setAddonAdded}
						isOpen={addonOpen}
						setIsOpen={setAddonOpen}
						onHide={onHidePlanBottomsheet}
						setState={setState}
					/>
				)}
				<ToastContainer />
			</div>
		</>
	);
}

export default Plan;
